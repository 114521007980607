import React from "react";
import { graphql } from "gatsby";
import EvoForm from "components/EvoForm";

export default function Form({
  headline,
  headlineTag,
  city,
  alignment,
  type,
  pageContext,
  onSuccess,
  apiId,
  clubName,
  code,
  firstStepIndex,
  curStepIndex,
  fullWidth,
  onStepChange,
  clubSelection,
  layout,
}) {
  return (
    <EvoForm
      code={code}
      firstStepIndex={firstStepIndex}
      curStepIndex={curStepIndex}
      onStepChange={onStepChange}
      apiId={apiId}
      clubName={clubName}
      city={city}
      type={type}
      onSuccess={(bool) => onSuccess(bool)}
      theme="default"
      alignment={alignment}
      headline={headline}
      headlineTag={headlineTag}
      pageContext={pageContext}
      fullWidth={fullWidth}
      clubSelection={clubSelection}
      layout={layout}
    />
  );
}

export const query = graphql`
  fragment MembershipFormQuery on Craft_MembershipTabCardsMembershipForm {
    id
    headline: sectionFormsHeadline
    headlineTag: sectionFormsHeadlineTag
    city: formType
    alignment: sectionFormsAlignment
    sectionFormsType: sectionFormsType
  }
`;
