import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';
import SelectBox from '../../../SelectBox';

export default function SelectGender() {
  const catalogue = useContext(CatalogueContext);

  const options = [
    { value: 'MALE', label: catalogue.male },
    { value: 'FEMALE', label: catalogue.female },
  ];

  return(
      <SelectBox
        name="gender"
        options={options}
        placeholder={catalogue.gender}
      />
  )

}
