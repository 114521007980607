import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import { colors, fonts, gradients } from "components/Layout/variables";

export const RibbonStyled = styled("div")({}, props => ({
  width: 150,
  height: 150,
  overflow: "hidden",
  position: "absolute",
  top: -15,
  right: -15,
  zIndex: 1,

  ".corner1, .corner2": {
    position: "absolute",
    zIndex: -1,
    display: "block",
    border: `5px solid ${colors.blieLight}`,
    borderTopColor: "transparent",
    borderRightColor: "transparent",
    zIndex: 1
  },

  ".corner2": {
    top: 0,
    left: 0
  },

  ".corner1": {
    bottom: 0,
    right: 0
  }
}));

export const TextStyled = styled("span")({
  left: -25,
  top: 30,
  transform: "rotate(45deg)",
  position: "absolute",
  display: "block",
  width: 225,
  padding: "15px 0",
  backgroundColor: colors.blue,
  boxShadow: "0 5px 10px rgba(0,0,0,.1)",
  color: "#fff",
  lineHeight: "18px",
  fontWeight: 700,
  fontSize: 11,
  // textTransform: 'uppercase',
  textAlign: "center"
});
