import styled from "@emotion/styled";
import media from "utils/mediaqueries";
import Headline from "components/Headline";

export const BenefitsImagesStyled = styled("div")(
  {
    marginBottom: 50,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "60px 0px 30px",

    [media(0, "mobile")]: {
      display: "none"
    }
  },
  props => ({
    background: props.theme.sectionBenefitImages,
    justifyContent: props.compact && "center",
    margin: props.compact && "50px 0"
  })
);

export const WrapperStyled = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  margin: "0 -25px"
});

export const ListItemImage = styled("div")({
  minWidth: "100%",
  textAlign: "center",
  borderRadius: 5,
  overflow: "hidden"
});

export const ListItemText = styled("div")({
  padding: "0px 5px",
  fontSize: "15px",
  textAlign: "center",
  width: "100%",
  textTransform: "uppercase",
  fontWeight: 900,
  marginTop: 20,

  [media("laptop")]: {
    fontSize: "11px"
  },

  [media("desktop")]: {
    fontSize: "15px"
  }
});

export const HeadlineStyled = styled(Headline)({
  lineHeight: "42px",
  textTransform: "uppercase",
  fontWeight: 900,
  maxWidth: "100%",
  textAlign: "center",
  fontSize: "34px",
  color: "#ffffff",
  margin: "0px 0 30px",
  letterSpacing: "1px",
  display: "block",
  width: "100%"
});

export const StyledListItem = styled("div")(
  {
    listStyle: "none",
    // single item on a row at mobile
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",

    [media(0, "mobile")]: {
      width: "100%",
      margin: "25px 0"
    },

    [media("tablet")]: {
      width: "calc(50% - 50px)",
      margin: 25
    },

    [media("tabletFluid")]: {
      width: "calc(33% - 50px)"
    },

    [media("laptop")]: {
      width: "calc(20% - 50px)"
    }
  },
  props => ({
    margin: props.compact && "20px 25px",
    width: props.compact && "auto"
  })
);
