import { styled } from "utils/emotion";

import Headline from "components/Headline";
import SwitchTheme from "components/SwitchTheme";

export const HeadlineStyled = styled(Headline)({});

export const SurveyWrapper = styled(SwitchTheme)({
  display: "flex",
  justifyContent: "center",
  paddingTop: 150,
  paddingBottom: 80,
});

export const ButtonContainerStyled = styled("div")({
  display: "flex",
  flexWrap: "wrap",
});

export const QuestionsStyled = styled("div")({
  borderTop: "1px solid rgba(255,255,255,0.2)",
  paddingTop: 40,
  marginTop: 40,
  maxWidth: "100%",
  textAlign: "center",
});

export const QuestionSliderStyled = styled("div")(
  {
    display: "flex",
    flexWrap: "nowrap",
    width: "100%",
    transition: "all 200ms ease-in-out",
  },
  ({ answered }) => ({
    transform: `translateX(-${answered * 100}%)`,
  })
);

export const QuestionSlideStyled = styled("div")(
  {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flexBasis: "100%",
    padding: "50px 0px",
    flexShrink: 0,
    alignItems: "center",
  },
  ({ active }) => ({
    transition: active
      ? "opacity 100ms ease-in-out 100ms"
      : "opacity 50ms ease-in-out",
    opacity: active ? 1 : 0,
    pointerEvents: active ? "all" : "none",
  })
);

export const Text = styled("div")({
  fontSize: ".9rem",
  color: "white",
  textAlign: "center",
  maxWidth: "450px",
  marginTop: "3rem",
  marginBottom: "3rem",
  // override Redactor style
  p: {
    fontWeight: 600,
  },
});

export const Content = styled("div")(
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 50,
    marginTop: 50,
    marginBottom: 50,
    borderRadius: 5,
    boxShadow: "0px 5px 30px rgba(0,0,0,0.6)",
    maxWidth: "100%",
  },
  ({ theme }) => ({ background: theme.sectionTextBackground })
);

export const IconWrapper = styled("div")({
  textAlign: "center",
});
