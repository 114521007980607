import scrollToElement from 'scroll-to-element';

export default function Helper() {

  function scrollToForm() {
    var elem = document.querySelector('.evo-form');
    scrollToElement(elem, { offset: -100 });
  }

  function fillFormWithDummyData() {
    var data = {"name":"Max","surname":"Mustermann","street":"Musterstraße 12","zip":"12345","phone":"012345678","gender":"MALE","birthdate-year":"1990","birthdate-month": "01", "birthdate-day": "01","email":"max.mustermann@musterfirma.de","password":"*Passwort12"};
    var data2 = {"payment_method":"SEPA","accepts_newsletter":"false"};

    for(var prop in data2) {
      var elm = document.querySelectorAll('input[name = "' + prop + '"][value="'+data2[prop]+'"]');
      var j;
      for (j = 0; j < elm.length; j++) {
        elm[j].checked = true;
        // elm[j].setAttribute('checked', true);
      }
    }

    for(var prop in data) {
        var elm = document.querySelectorAll('select[name = "' + prop + '"], input[name = "' + prop + '"]');
        var i;
        for (i = 0; i < elm.length; i++) {
          elm[i].value = data[prop];
          elm[i].setAttribute('value', data[prop]);
        }
    }
    // surname = document.querySelectorAll('input[name=surname]');
  }

  var devmode = false
  try {
    if(typeof window !== undefined) {
      if(window.location.search.indexOf("devmode=1") > -1) devmode = true
      if(window.location.hostname == 'forms.evofitness.de') devmode = true
    }
  } catch {
    devmode = false
  }

  return {
    fillFormWithDummyData: fillFormWithDummyData,
    scrollToForm: scrollToForm,
    devmode: devmode
  }
}
