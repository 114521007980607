import React, { useState } from "react";
import Button from "components/Button";
import SelectBox from "components/SelectBox";
import Text from "components/Text";
import Redactor from "../../Redactor";
import {
  Content,
  SurveyWrapper,
  HeadlineStyled,
  QuestionsStyled,
  QuestionSliderStyled,
  ButtonContainerStyled,
  QuestionSlideStyled,
} from "./styled";

const Survey = ({ survey, onFinish, email }) => {
  const {
    surveyQuestions,
    surveyHeadline,
    surveyIntro,
    surveyFinishText,
    surveyButtonNext,
    surveyButtonBack,
    surveyButtonSend,
  } = survey;
  const [answered, setAnswered] = useState(0);
  const [answers, setAnswers] = useState([]);

  const handleSend = () => {
    const answersString = answers
      .map(({ question, answer }) => `${question}: ${answer}`)
      .join(" | ");
    fetch("/.netlify/functions/cm", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({ email, survey: answersString }),
    })
      .then((response) => response.json())
      .then(async (result) => {
        onFinish();
      })
      .catch((err) => {
        throw `error: ${err?.message}` || JSON.stringify(err);
      });
  };

  const addAnswer = (answer) => {
    const newAnswers = [...answers];
    newAnswers[answered] = {
      question: surveyQuestions?.[answered]?.surveyQuestionShort,
      answer: answer || "keine Antwort",
    };
    setAnswers(newAnswers);
  };

  return (
    <SurveyWrapper name="green">
      <Content>
        <HeadlineStyled element="h2">{surveyHeadline}</HeadlineStyled>
        <Redactor content={surveyIntro?.content} color="black" />
        <QuestionsStyled>
          <HeadlineStyled element="h2">
            <span
              style={{
                opacity: answered <= surveyQuestions.length - 1 ? 1 : 0,
                pointerEvents:
                  answered <= surveyQuestions.length - 1 ? "all" : "none",
              }}
            >
              {answered + 1} / {surveyQuestions.length}
            </span>
          </HeadlineStyled>
          <QuestionSliderStyled answered={answered}>
            {surveyQuestions.map(
              (
                { surveyQuestion, surveyQuestionShort, surveyAnswers },
                index
              ) => (
                <QuestionSlideStyled
                  active={answered === index}
                  key={surveyQuestionShort}
                >
                  <Text>{surveyQuestion}</Text>
                  <div style={{ maxWidth: 500, width: "100%" }}>
                    <SelectBox
                      name="surveyQuestion"
                      options={surveyAnswers.map(
                        ({ surveyAnswers }, answerIndex) => ({
                          value: answerIndex,
                          label: surveyAnswers,
                        })
                      )}
                      onChange={(selectedOption) => {
                        addAnswer(selectedOption.label);
                        setAnswered(answered + 1);
                      }}
                      placeholder="---"
                    />
                  </div>
                </QuestionSlideStyled>
              )
            )}
            <QuestionSlideStyled active={answered === surveyQuestions.length}>
              <Redactor content={surveyFinishText?.content} />
            </QuestionSlideStyled>
          </QuestionSliderStyled>
        </QuestionsStyled>

        <ButtonContainerStyled>
          <Button
            stripped
            iconLeft
            icon="arrowLeft"
            style={{
              padding: "0 50px",
              opacity: answered > 0 ? 1 : 0,
              pointerEvents: answered > 0 ? "all" : "none",
            }}
            onClick={() => {
              setAnswered(answered - 1);
            }}
            isNotLink
          >
            {surveyButtonBack}
          </Button>
          {answered === surveyQuestions.length ? (
            <Button filled onClick={handleSend} isNotLink>
              {surveyButtonSend}
            </Button>
          ) : (
            <Button
              icon="arrowRight"
              filled
              onClick={() => {
                addAnswer();
                setAnswered(answered + 1);
              }}
              isNotLink
            >
              {surveyButtonNext}
            </Button>
          )}
        </ButtonContainerStyled>
      </Content>
    </SurveyWrapper>
  );
};

export default Survey;
