import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'
import { colors, fonts, gradients } from 'components/Layout/variables'
import { isBrowserIE11 } from 'utils/styles'
import Link from 'components/Link'
import Image from 'components/Image'
const utils = require('utils/styles')

export const CardLinkStyled = styled(Link)(
  {
    flex: 1,
    flexBasis: '100%',
    width: '100%',
    maxWidth: 400,
    flexGrow: 0,
    margin: 15,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 460,
    fontWeight: 700,
    backgroundColor: colors.white,
    color: colors.black,
    textAlign: 'center',
    fontFamily: fonts.secondary,
    transition: 'all .25s ease-in-out',
    boxShadow: '0px 5px 10px rgba(0,0,0,0.5)',
    cursor: 'pointer',
    border: `5px solid ${colors.white}`,

    [media('tablet')]: {},

    [media('laptop')]: {
      minHeight: '100%',
      flexBasis: 'calc(33.33% - 40px)',
      maxWidth: 'calc(33.33% - 40px)',
      width: 'calc(33.33% - 40px)',
    },

    '&:hover': {
      // zIndex: 3,
    },
  },
  (props) => ({ borderRadius: props.theme.borderRadiusPrimary }),
  () =>
    isBrowserIE11() && {
      // IE 11 doesn't play well with min-height when it comes to flexbox,
      // so we need to define a fixed height.
      height: 460,
    },
  (props) =>
    props.highlight === false && {
      [media('laptop')]: {
        transform: 'scale(1)',

        '&:hover': {
          transform: 'scale(1.01)',
        },
      },
    },
  (props) =>
    props.highlight && {
      border: `5px solid ${colors.green}`,
      minHeight: '100%',
      zIndex: 2,

      [media('laptop')]: {
        transform: 'scale(1.05)',

        '&:hover': {
          transform: 'scale(1.06)',
        },
      },
    }
)

export const HeaderStyled = styled('header')({
  position: 'relative',
  padding: '0 60px',
  marginTop: 40,
  flex: 'none',
})

export const HeadlineStyled = styled('h4')({
  fontSize: '1.5em',
  textTransform: 'uppercase',
  // fontWeight: 900,
})

export const TimeTextStyled = styled('div')({
  position: 'relative',
})

export const DigitsStyled = styled('span')({
  fontSize: 30,
  fontWeight: 600,
  transform: 'translateY(-28px)',
  display: 'inline-block',
  opacity: 0.7,
})

export const CurrencyStyled = styled('span')({
  fontSize: 30,
  fontWeight: 600,
  transform: 'translateY(-28px)',
  display: 'inline-block',
  opacity: 0.7,
})

export const HeaderTextBgStyled = styled('span')({
  display: 'block',
  fontSize: '70px',
  lineHeight: 1,
  fontWeight: 500,
  opacity: 1,
  marginBottom: 5,
  marginTop: 5,
  color: colors.green,
  // backgroundImage: utils.gradientLinear(
  //   '135deg',
  //   gradients.greenLightToBlue,
  // ),
  // '-webkit-background-clip': 'text',
  // '-webkit-text-fill-color': 'transparent',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export const HeaderTextStyled = styled('span')({
  display: 'block',
  textAlign: 'center',
  width: '100%',
  opacity: 0.5,
  fontSize: '1em',
  fontWeight: 500,
  letterSpacing: '.1em',
  // textTransform: 'uppercase',
})

export const ContentStyled = styled('main')({
  padding: '10px 20px 10px',
  position: 'relative',
})

export const RuntimeStyled = styled('span')({
  display: 'block',
  fontSize: '1.2em',
  fontWeight: 100,
  opacity: 0.5,
})

export const HrStyled = styled('hr')({
  width: '100%',
  opacity: 0.3,
  height: 1,
  border: 0,
  backgroundColor: colors.green,
  margin: '30px auto 20px',
})

export const TextStyled = styled('div')((props) => ({
  color: `${colors.black} !important`,
  fontSize: '.9em',
  fontWeight: 100,
  lineHeight: '1.7em',

  p: {
    color: 'black !important',
    fontSize: '.9em',
    fontWeight: 100,
    lineHeight: '1.7em',
  },

  ul: {
    textAlign: 'left',
    margin: '30px auto',
    padding: '0 10px 0 20px',
  },

  '&': props.listStyleImage && {
    ul: {
      listStyle: 'none',

      li: {
        position: 'relative',
        paddingLeft: parseFloat(props.listStyleImage.width) + 5,
        '&:before': {
          content: "' '",
          display: 'block',
          position: 'absolute',
          left: 0,
          top: 11,
          height: `${props.listStyleImage.height}px`,
          width: `${props.listStyleImage.width}px`,
          transform: 'translate(-50%,-50%)',
          background: `url(${
            props.listStyleImage.url
          }) no-repeat center center`,
          backgroundSize: 'contain',
        },
      },
    },

    li: {
      marginBottom: 10,
    },
  },

  'ul li': {
    color: 'black !important',
    fontSize: '0.9em',
    fontWeight: 100,
    lineHeight: '1.7em',
    // textIndent: "-17px",
    marginBottom: '5px',
  },
}))

export const OfferTextStyled = styled('p')({
  fontSize: '1em',
  fontWeight: 700,
  lineHeight: '1.7em',
  margin: '10px 0px 0px',
  color: colors.green,
})

export const DescriptionStyled = styled('p')({
  fontSize: '.9em',
  fontWeight: 100,
  lineHeight: '1.7em',
  margin: '30px 0px 10px',
})

export const ButtonStyled = styled('div')(
  {
    flex: 'none',
    color: colors.white,
    backgroundColor: colors.green,
    display: 'block',
    padding: '20px 10px',
    transition: 'background-color .25s',
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      backgroundColor: colors.greenDark,
    },
  },
  (props) => ({
    borderRadius: props.theme.borderRadiusPrimary,
  }),
  (props) =>
    props.scarcity && {
      position: 'absolute',
      width: 'calc(100% - 40px)',
      top: 12,
    }
)

export const FooterStyled = styled('footer')({
  padding: '10px 20px 20px',
  position: 'relative',
})

export const CardWrapperStyled = styled('div')({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  padding: '30px 0',

  [media('tabletFluid')]: {
    padding: '0px 0',
  },

  [media('laptop')]: {
    width: 'calc(100% + 100px)',
    margin: '0 -50px',
  },
})

export const OfferBadgeImageStyled = styled(Image)({
  // position: 'absolute',
  top: 0,
  left: -90,
  width: '100%',
  zIndex: 100,
  marginBottom: -200,
  maxWidth: '220px !important',

  [media(977, 1439)]: {
    maxWidth: '180px !Important',
    top: 20,
    left: -100,
  },

  [media(0, 'mobile')]: {
    maxWidth: '180px !Important',
    top: 20,
    left: -100,
  },
})
