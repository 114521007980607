// ENABLE CROSS SUBDOMAIN
try {
  document.domain = "evofitness.de";
} catch {
  const domain = "";
}

import { useState, useContext } from "react";
import ApiRequest from "./ApiRequest";
import ApiResponse from "./ApiResponse";
import ApiHelper from "./ApiHelper";
import { CatalogueContext } from "../Catalogue/Catalogue";
import { navigate } from "gatsby";

export default function useApi({
  redirectSuccess,
  onSuccessApi,
  showMessage,
  handleError,
  handleSuccess,
  api,
  handleStartPaymentValidation,
  locale,
  handleCancelPayment,
}) {
  const [sendFormInProgress, setSendFormInProgress] = useState(false);
  const [clubSelected, setClubSelected] = useState(false);
  const [codeEntered, setCodeEntered] = useState(false);
  const [quoteData, setQuoteData] = useState(false);
  const [paymentIframeUrl, setPaymentIframeUrl] = useState(false);
  const catalogue = useContext(CatalogueContext);

  const [createQuoteData, setCreateQuoteData] = useState(false);
  let prepareQuoteData = false;

  const [step, setStep] = useState("FORM-1");

  const { requestApi } = ApiRequest({ handleError, api });

  const { fillFormWithDummyData, scrollToForm, devmode } = ApiHelper();

  const {
    handleResponseTrialMember,
    handleResponseCreateQuote,
    handleResponsePrepareQuote,
    handleResponseCheckPayment,
    handleResponseEnrollMember,
  } = ApiResponse({ handleError, handleSuccess });

  let paymentInfo = {};
  let paymentCounter = 0;
  let paymentType = false;

  const baseUrl = "https://static.evofitness.de/forms/api/v2/";
  // var baseUrl = "https://forms.evofitness.de/"

  const steps = [
    { name: "FORM-1", step: 0 },
    { name: "FORM-2", step: 1 },
    { name: "FORM-3", step: 2 },
  ];

  function handleStartSignupApi(e) {
    setStep("FORM-2");
  }

  function handleContinueSignupApi(e) {
    setStep("FORM-3");
  }

  function handleSubmitTrial(e, formType) {
    e.preventDefault();
    const formData = new FormData(e.target);

    const credlockData = {
      data: {
        type: "trials",
        attributes: {
          club_id: formData.get("club"), // Club ID as an Identifier for the studio (e. g.  2 for Düsseldorf)
          email: formData.get("email"), // User input email
          first_name: formData.get("name"), // User input first name
          last_name: formData.get("surname"), // User input last name
          mobile_prefix: `+${formData.get("prefix")}`, // User input mobile number prefix (e. g. +49)
          mobile_number: parseInt(formData.get("phone"), 10), // User input 0170 1234567
          locale: locale == "de" ? "de-DE" : "en-DE", // language code depending on website langauage
          gdpr_opt_in: 1, // User choice for newsletter subscription
        },
      },
    };

    const onSuccess = () => {
      setSendFormInProgress(false);
      // console.log("onsuccess");

      // if (redirectSuccess) {
      //   navigate(redirectSuccess);
      // } else
      if (onSuccessApi) {
        // console.log("onSuccessApi");
        onSuccessApi(formData.get("email"));
      } else {
        showMessage(
          "success",
          catalogue.en.successTrialHeadline,
          catalogue.en.successTrialText
        );
      }
    };

    const onResponse = () => {
      setSendFormInProgress(false);
    };

    requestApi({
      url: `${baseUrl}api.php`,
      endpoint: "trial-member",
      data: credlockData,
      handleResponse: ({ json, data }) => {
        handleResponseTrialMember({
          data,
          json,
          onSuccess,
          onResponse,
        });
      },
    });

    setSendFormInProgress(true);
  }

  function selectClub(clubId) {
    if (clubId != clubSelected) {
      setClubSelected(clubId);
    }
  }

  function getQuote(clubId, code) {
    if ((clubId && clubId != clubSelected) || code !== codeEntered) {
      setClubSelected(clubId);
      setCodeEntered(code);
      setQuoteData(false);

      const data = {
        data: {
          type: "quotes",
          attributes: {
            club_id: clubId,
          },
        },
      };

      // data.data.attributes.code = "3LL"; // Jahr
      // data.data.attributes.code = "V9Z"; // Monat
      // data.data.attributes.code = "ZRF"; // 2 Jahre
      if (code) data.data.attributes.code = code;

      const onSuccess = (json) => {
        setCreateQuoteData(json.data);
      };

      const onResponse = (quoteData) => {
        setSendFormInProgress(false);
        setQuoteData(quoteData);
        if (devmode) fillFormWithDummyData();
      };

      requestApi({
        url: `${baseUrl}api.php`,
        endpoint: "create-quote",
        data,
        handleResponse: ({ json, data }) => {
          handleResponseCreateQuote({
            data,
            json,
            onSuccess,
            onResponse,
          });
        },
      });
    }
  }

  function handleSubmitSignup(e) {
    e.preventDefault();
    const formData = new FormData(e.target);

    const clubId = createQuoteData?.attributes?.club?.id || false;

    if (clubId) {
      const profileData = {
        email: formData.get("email"),
        first_name: formData.get("name"),
        last_name: formData.get("surname"),
        mobile_prefix: `+${formData.get("prefix")}`,
        mobile_number: parseInt(formData.get("phone"), 10),
        locale: locale == "de" ? "de-DE" : "en-DE",
        street: formData.get("street"),
        postal_code: formData.get("zip"),
        gender: formData.get("gender"),
        birthdate: `${formData.get("birthdate-year")}-${formData.get(
          "birthdate-month"
        )}-${formData.get("birthdate-day")}`,
        accepts_newsletter: formData.get("accepts_newsletter"),
        password: formData.get("password"),
      };

      const data = {
        data: {
          id: createQuoteData.id,
          type: "quotes",
          attributes: {
            club_id: clubId,
            product_id: createQuoteData.attributes.product.id,
            profile: profileData,
            prepare_payment: {
              payment_method: formData.get("payment_method"),
              callbacks: {
                success: `${baseUrl}success.php?`,
                cancel: `${baseUrl}cancel.php?`,
                // "cancel": baseUrl+"success.php?" // simulating success for dev purposes
              },
            },
          },
        },
      };

      const onSuccess = (json) => {
        prepareQuoteData = { ...json.data };
        intervalIframeCheck(json.data);
        setPaymentIframeUrl(json.data.attributes.prepare_payment.url);
        const paymentType =
          json.data.attributes.prepare_payment.payment_method == "CREDIT_CARD"
            ? "PAYMENT_CREDIT_CARD"
            : "PAYMENT_SEPA";
        setStep(paymentType);
        scrollToForm();
      };

      const onResponse = (quoteData) => {
        setSendFormInProgress(false);
        if (devmode) fillFormWithDummyData();
      };

      requestApi({
        url: `${baseUrl}api.php`,
        endpoint: "prepare-quote",
        data,
        handleResponse: ({ json, data }) => {
          handleResponsePrepareQuote({
            data,
            json,
            onSuccess,
            onResponse,
          });
        },
      });

      setSendFormInProgress(true);
    }
  }

  function intervalIframeCheck() {
    let iframeUrl = false;
    const paymentType =
      prepareQuoteData.attributes.prepare_payment.payment_method;
    const iframe =
      paymentType == "CREDIT_CARD"
        ? document.querySelectorAll("#paymentIframe")[0]
        : document.querySelectorAll("#paymentIframe iframe")[0];

    try {
      iframeUrl = iframe.contentWindow.location.href;
      if (iframeUrl && iframeUrl == "about:blank")
        setTimeout(() => {
          intervalIframeCheck();
        }, 200);
    } catch (err) {
      setTimeout(() => {
        intervalIframeCheck();
      }, 200);
    }

    if (iframeUrl && iframeUrl != "about:blank") {
      if (iframeUrl.indexOf("cancel") > -1) {
        setStep("FORM-2");
        handleCancelPayment();
        scrollToForm();
      } else if (
        iframeUrl.indexOf("success") > -1 ||
        iframeUrl.indexOf("slimpay/processing") > -1
      ) {
        setStep("PAYMENT_VALIDATION");
        handleStartPaymentValidation();
        scrollToForm();
        checkPayment();
      }
    }
  }

  function checkPayment() {
    paymentType = prepareQuoteData.attributes.prepare_payment.payment_method;

    if (paymentType == "CREDIT_CARD") {
      const paymentId = prepareQuoteData.attributes.payment.id;
      const endpoint = "check-credit-card";
      const data = {
        data: {
          paymentId,
        },
      };
    } else if (paymentType == "SEPA") {
      const quoteId = prepareQuoteData.id;
      const endpoint = "check-sepa";
      const data = {
        data: {
          quoteId,
        },
      };
    }

    const onSuccess = (returnedPaymentInfo) => {
      paymentInfo = returnedPaymentInfo;
      const sendEnrollRequest = paymentType !== "CREDIT_CARD"; // in case of creditcard credlock gets the feedback via DIBS and enrolls the member
      enrollMember(paymentInfo, sendEnrollRequest);
    };

    const onRepeat = () => {
      if (paymentCounter < 5) {
        setTimeout(() => {
          checkPayment();
        }, 1000);
        paymentCounter++;
      } else {
        paymentCounter = 0;
        handleError({ code: "ERROR_API_PAYMENT_TIMEOUT" });
      }
    };

    requestApi({
      url: `${baseUrl}api.php`,
      endpoint,
      data,
      handleResponse: ({ json, data }) => {
        handleResponseCheckPayment({
          data,
          paymentType,
          json,
          onSuccess,
          onRepeat,
        });
      },
    });
  }

  function enrollMember(paymentInfo, sendEnrollRequest) {
    if (!sendEnrollRequest) {
      if (onSuccessApi) {
        onSuccessApi();
      } else if (redirectSuccess) {
        navigate(redirectSuccess);
      } else {
        setStep("SUCCESS");
        scrollToForm();
      }
      return;
    }

    const endpoint = "enroll-member";
    const profile = prepareQuoteData.attributes.profile;

    const data = {
      data: {
        type: "members",
        attributes: {
          quote_id: prepareQuoteData.id,
          first_name: profile.first_name,
          last_name: profile.last_name,
          street: profile.street,
          postal_code: profile.postal_code,
          email: profile.email,
          mobile_prefix: profile.mobile_prefix,
          mobile_number: profile.mobile_number,
          gender: profile.gender,
          birthdate: profile.birthdate,
          password: profile.password,
          accepts_newsletter: profile.accepts_newsletter ? "true" : "false",
          locale: profile.locale,
        },
      },
    };

    if (paymentInfo.id >= 0) {
      data.data.attributes.payment_id = paymentInfo.id;
    }

    if (paymentInfo.subscriber_id) {
      data.data.attributes.slimpay_subscriber_id = paymentInfo.subscriber_id;
    }

    const onSuccess = () => {
      if (onSuccessApi) {
        onSuccessApi();
      } else if (redirectSuccess) {
        navigate(redirectSuccess);
      } else {
        setStep("SUCCESS");
        scrollToForm();
      }
    };

    requestApi({
      url: `${baseUrl}api.php`,
      endpoint,
      data,
      handleResponse: ({ json, data }) => {
        handleResponseEnrollMember({
          data,
          json,
          onSuccess,
        });
      },
    });
  }

  function handleOkayClickApi() {
    setStep("FORM-1");
  }

  return {
    handleSubmitTrial,
    sendFormInProgress,
    setSendFormInProgress,
    getQuote,
    handleSubmitSignup,
    paymentIframeUrl,
    clubSelected,
    selectClub,
    step,
    quoteData,
    handleOkayClickApi,
    handleContinueSignupApi,
    handleStartSignupApi,
  };
}
