import React, { useContext } from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function InputAddress({}) {
  const catalogue = useContext(CatalogueContext);

  return (
    <>
      <div className="evo-form__input-wrap">
        <input className="evo-form__input" type="text" name="street" placeholder={catalogue.street} required />
      </div>
      <div className="evo-form__input-wrap">
        <input className="evo-form__input" type="text" name="zip" placeholder={catalogue.zip} required />
      </div>
    </>
  )
}
