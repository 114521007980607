import React from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import WrapGrid from "components/WrapGrid";
import Overlay from "components/Overlay";
import Image from "components/Image";
import HeroFixed from "components/HeroFixed";
import BenefitsImages from "components/BenefitsImages";
import GalleryTile from "components/GalleryTile";
import Slider from "components/Slider";
import { styled } from "utils/emotion";

import Toggle from "./Toggle";
import RegistrationProcess from "./RegistrationProcess";

import {
  MembershipStyled,
  MembershipInnerStyled,
  HeadlineStyled,
  BgStyled,
} from "./styles";

const GallerySectionStyled = styled("section")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  margin: "0px 0px 20px",
  backgroundColor: "#120f0b",
  position: "relative",
  zIndex: 1,
  lineHeight: 0,
});

export default function Memberships({
  header,
  tabs,
  sectionFormsBenefitsHeadline,
  sectionFormBenefits,
  sectionFormBenefitsGallery,
  clubSelection,
  pageContext,
}) {
  let memberships = null;
  let survey = null;
  let clubs = null;

  if (pageContext) {
    const staticData = useStaticQuery(graphql`
      query MembershipGlobalsStatic {
        craft {
          # Globals
          globalsDe: globals(siteId: 1) {
            ...GlobalMembershipOptionsQuery
          }

          globalsEn: globals(siteId: 2) {
            ...GlobalMembershipOptionsQuery
          }

          entriesDe: entries(siteId: 1, type: PagesPagesClubs) {
            ...ClubQuery
          }

          entriesEn: entries(siteId: 2, type: PagesPagesClubs) {
            ...ClubQuery
          }
        }
      }
    `);

    const { globalsDe, globalsEn, entriesDe, entriesEn } = staticData?.craft;

    const globals = pageContext.siteId === 1 ? globalsDe : globalsEn;
    clubs = pageContext.siteId === 1 ? entriesDe : entriesEn;

    memberships = [...globals.memberships.membershipOptions];
    survey = {
      surveyQuestions: globals.memberships.survey,
      surveyHeadline: globals.memberships.surveyHeadline,
      surveyFinishText: globals.memberships.surveyFinishText,
      surveyButtonNext: globals.memberships.surveyButtonNext,
      surveyButtonBack: globals.memberships.surveyButtonBack,
      surveyButtonSend: globals.memberships.surveyButtonSend,
      surveyIntro: globals.memberships.surveyIntro,
    };
  }
  return (
    <MembershipStyled padding={header?.headline !== null}>
      {sectionFormBenefits.length <= 0 && sectionFormBenefitsGallery <= 0 && (
        <>
          <BgStyled>
            <Image
              fluid={header.backgroundImage?.[0]}
              args={{ maxWidth: 1920 }}
              style={{
                height: "100vh",
                width: "100vw",
                top: 0,
                left: 0,
                position: "fixed",
              }}
            />
            <Overlay />
            <Overlay />
          </BgStyled>
          <HeroFixed {...header} />
        </>
      )}
      <MembershipInnerStyled>
        {sectionFormBenefits.length > 0 && (
          <BenefitsImages
            listItem={sectionFormBenefits}
            headline={sectionFormsBenefitsHeadline}
          />
        )}
        {sectionFormBenefitsGallery.length > 0 && (
          <>
            <HeadlineStyled>{sectionFormsBenefitsHeadline}</HeadlineStyled>
            <GallerySectionStyled>
              <Slider noPadding gutter={0}>
                {sectionFormBenefitsGallery.map(
                  (
                    {
                      galleryImageText,
                      galleryImageTitle,
                      galleryImageHeadline,
                      galleryImage,
                    },
                    index
                  ) => (
                    <GalleryTile
                      key={"gallery-tile-" + index}
                      text={galleryImageText}
                      title={galleryImageTitle}
                      headline={galleryImageHeadline}
                      image={galleryImage}
                    />
                  )
                )}
              </Slider>
            </GallerySectionStyled>
          </>
        )}
        <Toggle tabs={tabs} />
        <WrapGrid>
          {tabs.map((tab) => (
            <div key={tab.sectionFormsTabSlug}>
              {tab.sectionFormsSelected && (
                <RegistrationProcess
                  clubs={clubs}
                  memberships={memberships}
                  survey={survey}
                  headline={tab.sectionFormsFormHeadline}
                  sectionFormsType={tab.sectionFormsFormType}
                  pageContext={pageContext}
                  clubSelection={tab.sectionFormsFormClubs}
                  layout={tab.trialFormLayout}
                />
              )}
            </div>
          ))}
        </WrapGrid>
      </MembershipInnerStyled>
    </MembershipStyled>
  );
}

Memberships.propTypes = {
  tabs: PropTypes.array,
};

Memberships.defaultProps = {
  tabs: [],
};

export const query = graphql`
  fragment ClubQuery on Craft_PagesPagesClubs {
    id
    title
    locationZip
    locationCode
    locationApiId
    locationAddress
    locationBoxText
    locationExternalLink {
      url
    }
    locationCity {
      title
      slug
      cityIcon {
        ...CraftImageFluid
      }
    }
    locationTrialSuccessText
  }

  fragment GlobalMembershipOptionsQuery on Craft_GlobalsSet {
    memberships {
      surveyHeadline
      surveyButtonNext
      surveyButtonBack
      surveyButtonSend
      surveyFinishText {
        content
      }
      surveyIntro {
        content
      }
      survey {
        surveyQuestion
        surveyQuestionShort
        surveyAnswers {
          surveyAnswers
        }
      }
      membershipOptions {
        id
        membershipOptionTitle
        membershipOptionDescription
        membershipOptionQuantity
        membershipOptionTimeunit
        membershipOptionOfferText
        membershipOptionBulletImage {
          ...CraftImageFluid
        }
        membershipOptionOfferBadge {
          ...CraftImageFluid
        }
        membershipOptionPrice
        membershipOptionPriceCurrency
        membershipOptionPriceTimeunit
        membershipOptionText {
          totalPages
          content
        }
        membershipOptionCode
        membershipOptionLink {
          text
          url
          entry {
            fullUri
          }
        }
        membershipOptionCheckout
        membershipOptionHighlight
        membershipOptionRibbon
        membershipOptionSuccessText
        scarcity {
          ... on Craft_ScarcityScarcity {
            textBefore
            textAfter
            totalCount
            lastNumber
            startDate
            endDate
          }
        }
      }
    }
  }
`;
