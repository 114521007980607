// Hard coding the cities is not good! A more generic solution should be implemented!
export const mapCity = selectedCity => {
  switch (selectedCity) {
    case "Düsseldorf":
      return "duesseldorf";
    case "Berlin":
      return "berlin";
    case "Hamburg":
      return "hamburg";
    default:
      return "default";
  }
};

export function trackEvent(formType, progressIndex, label) {
  if (typeof window.ga === "function") {
    const data = {
      userAgent: navigator && navigator.userAgent ? navigator.userAgent : null,
      url: window.location || null
    };
    window.ga(
      "send",
      "event",
      "Registration",
      `Step-${progressIndex}`,
      label,
      JSON.stringify(data)
    );
  }
}
