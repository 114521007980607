import React from 'react';
import {
  ProgressBarStepsWrapper,
  ProgressBarWrapper,
  ProgressLine,
  ProgressStepName,
  ProgressStepWrapper,
} from './styled';

const ProgressStep = ({ visited, stepName, onClick }) => (
  // if visited paint blue
  <ProgressStepWrapper>
    <ProgressStepName
      onClick={() => onClick({ visited, stepName })}
      check={visited}
    >
      {stepName}
    </ProgressStepName>
  </ProgressStepWrapper>
);
const Line = ({ views, curIndex }) => {
  const numberOfView = views.length;
  const lineWidth = (curIndex / (numberOfView - 1)) * 100; // in %
  return <ProgressLine width={lineWidth} />;
};

const ProgressBar = ({ views, curViewIndex, onProcessPointClick }) => {
  return (
    <ProgressBarWrapper>
      <ProgressBarStepsWrapper>
        {/* show progress steps */}
        {views.map(({ name }, mapIndex) => (
          <ProgressStep
            // paint step blue if user is on the view or
            // the current view index is bigger previous views idx
            // paint all visited views blue
            visited={mapIndex <= curViewIndex}
            stepName={name}
            onClick={step => onProcessPointClick(mapIndex)}
            key={`step-${name}-${mapIndex}`}
          />
        ))}
        {/* line */}
        <Line curIndex={curViewIndex} views={views} />
      </ProgressBarStepsWrapper>
    </ProgressBarWrapper>
  );
};

export default ProgressBar;
