import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

import Headline from "components/Headline";
import TextVisual from "components/TextVisual";

export const HeroFixedStyled = styled("section")({
  position: "relative",
  zIndex: 1,
  [media("tabletFluid")]: {
    alignItems: "center",
    justifyContent: "center",
  },
});

export const ContentStyled = styled("div")({});

export const TextVisualStyled = styled(TextVisual)({
  position: "relative",
  left: 0,
  top: 0,
  wordSpacing: 0,
  fontSize: "9vw",

  [media("tabletFluid")]: {
    fontSize: "7vw",
  },

  [media("laptop")]: {
    fontSize: "6vw",
  },

  [media(1280)]: {
    fontSize: "6vw",
  },

  [media(1300)]: {
    fontSize: "5vw",
  },

  [media("desktop")]: {
    fontSize: 65,
  },
});

export const HeadlineStyled = styled(Headline)(
  {
    position: "relative",
    wordBreak: "normal",
    transform: "translate(0,-20%)",
    marginBottom: 0,

    [media("tablet")]: {},

    [media("tabletFluid")]: {
      transform: "translate(0,-20%)",
      textAlign: "center",
    },

    [media("laptop")]: {
      transform: "translate(0,-60%)",
    },

    [media("desktop")]: {},
  },
  (props) => ({
    [media(375)]: {
      padding: `0 ${props.theme.space}px`,
    },
  })
);
