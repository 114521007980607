import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function OptionNewsletter({}) {
  const catalogue = useContext(CatalogueContext);

  const randId = Math.random().toString(36).substr(2, 9);

  return(
    <div>
      <p className="evo-form__text">{catalogue.newsletterText} <a href={catalogue.newsletterPrivacyLink} target="_blank">{catalogue.newsletterPrivacyLinkText}</a></p>
      <div className="evo-form__option-row">
        <input className="evo-form__input" id={"newsletter-agree-"+randId} type="radio" value="true" name="accepts_newsletter" />
        <label className="evo-form__label" htmlFor={"newsletter-agree-"+randId}>
          {catalogue.newsletterYes}
        </label>
      </div>
      <div className="evo-form__option-row">
        <input className="evo-form__input" id={"newsletter-disagree-"+randId} type="radio" value="false" name="accepts_newsletter" />
        <label className="evo-form__label" htmlFor={"newsletter-disagree-"+randId}>
            {catalogue.newsletterNo}
        </label>
      </div>
    </div>
  )

}
