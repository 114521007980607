import React from 'react'
import PropTypes from 'prop-types'
import Link from 'components/Link'
import Redactor from 'components/Redactor'
import Ribbon from 'components/Memberships/Ribbon'

import {
  CardLinkStyled,
  HeaderStyled,
  HeaderTextBgStyled,
  HeaderTextStyled,
  ContentStyled,
  RuntimeStyled,
  HrStyled,
  TextStyled,
  FooterStyled,
  DescriptionStyled,
  HeadlineStyled,
  TimeTextStyled,
  ButtonStyled,
  OfferBadgeImageStyled,
  OfferTextStyled,
  CurrencyStyled,
  DigitsStyled,
} from './styles'
import Scarcity from '../../Scarcity/Scarcity'

export default function Card({
  onChoose,
  signupEnabled,
  code,
  title,
  description,
  highlight,
  link,
  price,
  priceCurrency,
  priceTimeUnit,
  bulletImage,
  quantity,
  signupSuccessText,
  text,
  timeUnit,
  offerBadge,
  ribbonText,
  pageContext,
  offerText,
  scarcity,
}) {
  const offerBadgeImage = offerBadge[0]

  const priceDigits = price.split(',')
  const priceFirst = priceDigits[0]
  const priceSecond = priceDigits[1]

  const locale = pageContext.language
  const listStyleImage = bulletImage?.[0]

  return (
    <CardLinkOrDiv
      highlight={highlight}
      onClick={() =>
        signupEnabled && onChoose
          ? onChoose({ formSuccessText: signupSuccessText, code })
          : null
      }
      code={code}
      signupEnabled={signupEnabled}
      link={link?.entry?.fullUri || link?.url || null}>
      {ribbonText && <Ribbon text={ribbonText} />}

      {title && (
        <HeaderStyled>
          {title && <HeadlineStyled>{title}</HeadlineStyled>}
          {offerText && <OfferTextStyled>{offerText}</OfferTextStyled>}
          {description && <DescriptionStyled>{description}</DescriptionStyled>}
        </HeaderStyled>
      )}
      <ContentStyled>
        {offerBadgeImage && (
          <OfferBadgeImageStyled
            fluid={offerBadgeImage}
            aspectRatio={offerBadgeImage.width / offerBadgeImage.height}
            style={{ maxWidth: 250, position: 'absolute' }}
            objectFit="containt"
          />
        )}
        <TimeTextStyled>
          {priceTimeUnit && (
            <HeaderTextStyled>{priceTimeUnit}</HeaderTextStyled>
          )}
          <HeaderTextBgStyled>
            {locale == 'en' && <CurrencyStyled>{priceCurrency}</CurrencyStyled>}
            {priceFirst}
            {priceSecond && <DigitsStyled> {priceSecond}</DigitsStyled>}
            {locale == 'de' && <CurrencyStyled>{priceCurrency}</CurrencyStyled>}
          </HeaderTextBgStyled>
        </TimeTextStyled>
        {quantity && timeUnit && (
          <RuntimeStyled>
            {quantity} {timeUnit}
          </RuntimeStyled>
        )}
        <HrStyled />
        {text && (
          <TextStyled listStyleImage={listStyleImage}>
            <Redactor content={text.content} color="black" />
          </TextStyled>
        )}
      </ContentStyled>
      {(link?.entry || link?.url || null) && (
        <FooterStyled>
          <Scarcity scarcity={scarcity?.[0] || null} type="button" />
          <ButtonStyled scarcity={!!scarcity?.[0]}>{link.text}</ButtonStyled>
        </FooterStyled>
      )}
    </CardLinkOrDiv>
  )
}

const CardLinkOrDiv = ({ children, link, code, signupEnabled, ...props }) => {
  if ((signupEnabled && !code && link) || (!signupEnabled && link)) {
    return (
      <CardLinkStyled to={link} {...props}>
        {children}
      </CardLinkStyled>
    )
  }
  const CardDivStyled = CardLinkStyled.withComponent('div')
  return <CardDivStyled {...props}>{children}</CardDivStyled>
}

Card.propTypes = {
  quantity: PropTypes.string.isRequired,
  timeUnit: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  priceCurrency: PropTypes.string.isRequired,
  priceTimeUnit: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  text: PropTypes.shape({
    content: PropTypes.string,
  }),
  link: PropTypes.shape({
    url: PropTypes.string,
    entry: PropTypes.object,
  }).isRequired,
  signupEnabled: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  highlight: PropTypes.bool,
  signupSuccessText: PropTypes.string,
}

Card.defaultProps = {
  text: '',
  title: '',
  description: '',
  signupSuccessText: '',
  signupEnabled: false,
  highlight: false,
}
