import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function CheckAgree({agbText}) {
  const catalogue = useContext(CatalogueContext);

    const randId = Math.random().toString(36).substr(2, 9);

  return(
    <div>
      <div className="evo-form__option-row"  >
        <input className="evo-form__input" id={"checkbox-agree-"+randId} type="checkbox" name="checkbox-agree" required />
        <label className="evo-form__label" htmlFor={"checkbox-agree-"+randId}>{agbText} <a href={catalogue.agbLink} target="_blank">({catalogue.agbLinktext})</a></label>
      </div>
    </div>
  )
}
