import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function InputPassword({}) {
  const catalogue = useContext(CatalogueContext);


  function testInput(groupSelector) {
    const selector = 'input[name=password]'
    const inputs = document.querySelectorAll(selector)

    let validation = true;
    inputs.forEach((input) => {
      const valid = input.checkValidity()
    })

    return validation
  }

  return(
    <>
      <p className="evo-form__text">{catalogue.password}</p>
      <input className="evo-form__input" onChange={testInput} placeholder={catalogue.passwordShort} pattern="^(?=.*[\d\W])(?=.*[a-z])(?=.*[A-Z]).{8,100}$" name="password" type="password" autoComplete="off" required />
    </>
  )
}
