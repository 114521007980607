import React, { useContext } from "react";
import { CatalogueContext } from "../Catalogue/Catalogue";
import Loader from "../Loader";

export default function Quote({ quoteData, locale, children }) {
  const catalogue = useContext(CatalogueContext);

  if (quoteData) {
    const paymentDateArray = quoteData.next_payment.date.split("-");
    const paymentDate = new Date(
      Date.UTC(
        paymentDateArray[0],
        paymentDateArray[1] - 1,
        paymentDateArray[2],
        0,
        0,
        0
      )
    );

    const signup_presentation_locale = quoteData.signup_presentation
      ? quoteData.signup_presentation.split(/\-{4,}|_{4,}/g)
      : [];
    const signup_presentation_en = signup_presentation_locale[1]
      ? signup_presentation_locale[1]
      : signup_presentation_locale[0];
    const signup_presentation_de = signup_presentation_locale[0];
    const signup_presentation =
      locale == "de" ? signup_presentation_de : signup_presentation_en;

    return (
      <div className="evo-form__quote">
        <h2>{catalogue.quoteHeadline}</h2>
        {/* <section className="evo-form__quote-maininfo">
          <p>Ihr Hauptcenter<br/><br/> ist 365 Tage im Jahr geöffnet<br/>Landesweit vertreten</p>
        </section> */}

        <section className="evo-form__quote-current">
          {quoteData.signup_presentation && (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<p>" +
                  signup_presentation
                    .trim()
                    .split("\n")
                    .join("<br />") +
                  "</p>"
              }}
            />
          )}
          {!quoteData.signup_presentation && <p>{catalogue.quoteIntro}</p>}
          <table>
            <tbody>
              {quoteData.current_payment.lines.map((quoteLine, index) => {
                return (
                  <tr key={index}>
                    <th>
                      {catalogue["quoteline_" + quoteLine.concept] ||
                        quoteLine.concept}
                    </th>
                    <td>
                      {new Intl.NumberFormat(catalogue.locale, {
                        style: "currency",
                        currency: catalogue.currency
                      }).format(quoteLine.amount)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th>{catalogue.quoteTotal}</th>
                <td>
                  {new Intl.NumberFormat(catalogue.locale, {
                    style: "currency",
                    currency: catalogue.currency
                  }).format(quoteData.current_payment.amount)}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="evo-form__quote-next">
          <p>
            {catalogue.quoteNextPaymentOn}{" "}
            {new Intl.DateTimeFormat(catalogue.locale).format(paymentDate)}
          </p>
          <table>
            <tbody>
              <tr>
                <th>{catalogue.quoteNextPayment}</th>
                <td>
                  {new Intl.NumberFormat(catalogue.locale, {
                    style: "currency",
                    currency: catalogue.currency
                  }).format(quoteData.next_payment.amount)}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        {children}
      </div>
    );
  } else {
    return (
      <div>
        <h2>
          {catalogue.quoteHeadline} {catalogue.quoteHeadlineLoading}
        </h2>
        <Loader />
      </div>
    );
  }
}
