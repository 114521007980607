import { styled } from "utils/emotion";
import Headline from "components/Headline";

export const HeadlineStyled = styled(Headline)({
  lineHeight: "42px",
  textTransform: "uppercase",
  fontWeight: 900,
  maxWidth: "100%",
  textAlign: "center",
  fontSize: "34px",
  color: "#ffffff",
  paddingTop: "50px",
  margin: "0px 0 30px",
  letterSpacing: "1px",
});
