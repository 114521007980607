import ApiHelper from "./ApiHelper";

export default function ApiRequest({ handleError, api = "de" }) {
  const { devmode } = ApiHelper();
  function requestApi({ data, url, endpoint, handleResponse }) {
    // const encoededJson = encodeURIComponent(json);

    const json = {
      api,
      data,
      endpoint,
      debug: devmode && 1,
    };

    const jsonString = JSON.stringify(json);

    // console.log("request");
    if (window?.location?.search?.indexOf("skipform=1") > -1) {
      handleResponse({ json: { data: true }, data: { success: true } });
    } else {
      fetch(url, {
        // mode: 'no-cors',
        method: "POST",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: jsonString,
      })
        .then((response) => response.json())
        .then((json) => {
          handleResponse({ json, data });
        })
        .catch((error) => {
          json.errorMessage = error;
          handleError({ code: "ERROR_API_INVALIDRESPONSE", data });
        });
    }
  }

  return { requestApi };
}
