import React, { createContext } from "react";

const defaultCatalogue = {
  de: {
    name: "Vorname",
    surname: "Nachname",
    street: "Straße",
    zip: "PLZ (keine Ortsangabe notwendig)",
    mobile: "Mobil",
    email: "E-Mail",
    birthdate: "Geburtsdatum",
    birthdateYear: "JJJJ",
    birthdateMonth: "MM",
    birthdateDay: "TT",
    gender: "Geschlecht",
    female: "Weiblich",
    male: "Männlich",
    agbTrial:
      "Hiermit bestätige ich, dass ich mindestens 18 Jahre alt bin, die Bedingungen für Mitglieder AGB akzeptiert habe und mit der Verarbeitung und Speicherung meiner persönlichen Daten laut EVOs Datenschutzerklärung, um EVOs Nachrichten zu erhalten, einverstanden bin.",
    agbSignup:
      "Hiermit bestätige ich, dass ich mindestens 18 Jahre alt bin, die Allgemeinen Geschäftsbedingungen gelesen und akzeptiert habe und alle oben angegebenen Telefon- und Adressdaten korrekt sind. ",
    agbLinktext: "AGB",
    agbLink: "https://evofitness.de/agb",
    sendTrial: "Mein Probetraining sichern",
    sendSignup: "Wähle deine Zahlungsmethode",
    selectClub: "Club",
    headlineSelectClub: "Wähle deinen Club",
    messageBoxClose: "ok",
    paymentSepa: "Bankeinzug",
    paymentCreditCard: "Kreditkarte",
    newsletterText:
      'Wir würden dir gerne unsere neuesten Workouts, Tipps für das Training, Einladungen zu Events und interessante Angebote schicken - bitte wähle "Ja" und genieße alle Vorzüge von EVO. Du kannst deine Einstellungen jederzeit ändern. Lies mehr über unsere Datenschutzrichtlinie und wie wir deine persönlichen Informationen verwalten',
    newsletterPrivacyLink: "https://evofitness.de/datenschutz",
    newsletterPrivacyLinkText: "Datenschutzrichtlinie",
    newsletterNo:
      "Nein, ich möchte von EVO keine neuen Workouts, Tipps für das Training, Einladungen zu Events und interessante Angebote erhalten.",
    newsletterYes:
      "Ja, ich möchte gerne EVO's neueste Workouts, Tipps für das Training, Einladungen zu Events und interessante Angebote erhalten.",
    password:
      "Passwort (mindestens 8 Zeichen lang und mindestens jeweils 1 Großbuchstabe, 1 kleiner Buchstabe, 1 Ziffer oder Sonderzeichen)",
    credentialsInfo:
      "Diese Anmeldedaten dienen auch als Zugang für die App und MyEVO.",
    passwordShort: "Passwort",
    headlineContact: "Kontaktdaten",
    headlineCredentials: "Zugangsdaten",
    headlineCode: "Aktions-Code",
    code: "code",
    headlinePayment: "Bezahlung",
    headlinePrivacy: "Deine Privatsphäre-Einstellungen",
    headlineNext: "Nächster Schritt",
    quoteline_SIGNUP_FEE: "Anmeldegebühr",
    quoteline_BASE_SERVICE_REST_OF_MONTH_FEE:
      "Trainingsgebühr, laufender Monat",
    quoteTotal: "Gesamt",
    quoteNextPaymentOn: "Nächste Zahlung am",
    quoteNextPayment: "Ihre nächste Bezahlung",
    quoteIntro: "Heute bezahlst du für folgende Leistung",
    quoteHeadline: "Deine Mitgliedschaft",
    quoteHeadlineLoading: "wird geladen...",
    currency: "EUR", // Must be as described here https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    locale: "de-DE", // Must be as described here https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
    successSignupHeadline: "Vielen Dank!",
    successSignupText: "Du bist nun angemeldet. Wir freuen uns auf Dich.",
    successTrialHeadline: "Vielen Dank!",
    successTrialText:
      "Dein Zugangscode ist unterwegs auf dein Smartphone. Wir freuen uns auf Dich.",
    startSignup: "Weiter zur Bezahlung",
    continueSignup: "Weiter",
    error: {
      ERROR_API_XHR_ERRPOR: {
        headline: "Ein Fehler ist aufgetreten",
        message: "Bitte versuche es später nocheinmal.",
      },
      ERROR_GENERAL: {
        headline: "Ein Fehler ist aufgetreten",
        message:
          "Es scheint als hättest du dich schon einmal angemeldet. Bitte wende dich an info@evofitness.de, damit wir dir helfen können.",
      },
      ERROR_API_PAYMENT_NOVALIDATION: {
        headline: "Ein Fehler ist aufgetreten",
        message:
          "Die Zahlung konnte nicht überprüft werden. Bitte wenden Sie sich an unseren support",
      },
      ERROR_API_PAYMENT_NOVALIDATION: {
        headline: "Ein Fehler ist aufgetreten",
        message:
          "Die Zahlung konnte nicht überprüft werden. Bitte wenden Sie sich an unseren support",
      },
      ERROR_API_PAYMENT_CREDIT_CARD_INVALID: {
        headline: "Ein Fehler ist aufgetreten",
        message: "Die Zahlung wurde nicht akzeptiert.",
      },
      ERROR_API_PAYMENT_CREDIT_CARD_UNLISTED_ERROR: {
        headline: "Ein Fehler ist aufgetreten",
        message:
          "Die Zahlung konnte nicht überprüft werden. Bitte wenden Sie sich an unseren support",
      },
      ERROR_API_PAYMENT_REJECTED: {
        headline: "Bezahlung abgelehnt",
        message:
          "Die Bezahlung wurde abgelehnt. Bitte versuche es später nocheinmal.",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL_INVALID: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Die E-Mail ist ungültig.",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL_EMPTY: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Die E-Mail darf nicht leer sein.",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL_TAKEN: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Die E-Mail ist bereits registriert.",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Bei der Validierung der E-Mail ein Problem aufgetreten.",
      },
      ERROR_API_VALIDATION_ERROR_PHONE_INVALID: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Die Telefonnummer ist ungültig.",
      },
      ERROR_API_VALIDATION_ERROR_PHONE_EMPTY: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Die Telefonnummer darf nicht leer sein.",
      },
      ERROR_API_VALIDATION_ERROR_PHONE_TAKEN: {
        headline: "Bitte überprüfe deine Eingabe",
        message: "Die Telefonnummer ist bereits registriert.",
      },
      ERROR_API_VALIDATION_ERROR_PHONE: {
        headline: "Bitte überprüfe deine Eingabe",
        message:
          "Bei der Validierung der Telefonnummer ein Problem aufgetreten.",
      },
      ERROR_API_NOT_ACCEPTABLE: {
        headline: "error info from Sepa",
        message: "Slimpay order NOT successfully completed",
      },
      ERROR_API_RECORD_NOT_FOUND: {
        headline: "Record not found",
        message: "The record identified could not be found.",
      },
    },
  },
  en: {
    name: "First Name",
    surname: "Surname",
    street: "Street",
    zip: "Postal code (no city required)",
    mobile: "Mobile",
    email: "E-mail",
    birthdate: "Birthdate",
    birthdateYear: "JJJJ",
    birthdateMonth: "MM",
    birthdateDay: "TT",
    gender: "Gender",
    female: "Female",
    male: "Male",
    agbTrial:
      "I hereby confirm that I am at least 18 years old, have accepted the Terms and Conditions for Members and agree to the processing and storage of my personal data according to EVO's Privacy Policy to receive EVO's messages.",
    agbSignup:
      "I hereby certify that I am at least 18 years of age, have read and accepted the Terms and Conditions and that all telephone and address details above are correct. ",
    agbLinktext: "GTC",
    agbLink: "https://evofitness.de/en/terms-of-service",
    sendTrial: "Get my trial training now",
    sendSignup: "choose your payment method",
    selectClub: "Club",
    headlineSelectClub: "Choose your club",
    messageBoxClose: "ok",
    paymentSepa: "SEPA",
    paymentCreditCard: "Credit Card",
    newsletterText:
      'We would like to send you our latest workouts, training tips, invitations to events and interesting offers - please choose "Yes" and enjoy all the benefits of EVO. You can change your settings at any time. Read more about our privacy policy and how we manage your personal information',
    newsletterPrivacyLink: "https://evofitness.de/en/privacy-policy",
    newsletterPrivacyLinkText: "Privacy Policy",
    newsletterNo:
      "No, I don't want to receive new workouts, training tips, invitations to events and interesting offers from EVO",
    newsletterYes:
      "Yes, I would like to receive EVO's latest workouts, training tips, event invitations and interesting offers.",
    password:
      "Password (at least 8 characters long and at least 1 capital letter, 1 small letter, 1 digit or special character)",
    passwordShort: "password",
    headlineContact: "Contact information",
    credentialsInfo: "This is also your login for the app und MyEVO.",
    headlineCredentials: "credentials",
    headlinePayment: "Payment",
    headlinePrivacy: "Your privacy settings",
    headlineNext: "Next step",
    headlineCode: "Code",
    code: "code",
    quoteline_SIGNUP_FEE: "Registration fee",
    quoteline_BASE_SERVICE_REST_OF_MONTH_FEE: "Training fee, current month",
    quoteTotal: "Total",
    quoteNextPaymentOn: "Next payment on",
    quoteNextPayment: "Your next payment",
    quoteIntro: "Today you pay for the following service",
    quoteHeadline: "Offer",
    quoteHeadlineLoading: "is loading...",
    currency: "EUR", // Must be as described here https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    locale: "en-EN", // Must be as described here https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
    successSignupHeadline: "Thank you very much",
    successSignupText: "You are now logged in. We are looking forward to you.",
    successTrialHeadline: "Thank you very much",
    successTrialText:
      "Your access code is on its way to your smartphone. We are looking forward to you.",
    startSignup: "proceed to payment",
    continueSignup: "Continue",
    error: {
      ERROR_API_XHR_ERRPOR: {
        headline: "An error has occurred",
        message: "Please try again later.",
      },
      ERROR_GENERAL: {
        headline: "An error has occurred",
        message:
          "It seems that you have registered before. Please contact info@evofitness.de so that we can help you.",
      },
      ERROR_API_PAYMENT_NOVALIDATION: {
        headline: "An error has occurred",
        message: "The payment could not be checked. Please contact our support",
      },
      ERROR_API_PAYMENT_NOVALIDATION: {
        headline: "An error has occurred",
        message: "The payment could not be checked. Please contact our support",
      },
      ERROR_API_PAYMENT_CREDIT_CARD_INVALID: {
        headline: "An error has occurred",
        message: "The payment was not accepted",
      },
      ERROR_API_PAYMENT_CREDIT_CARD_UNLISTED_ERROR: {
        headline: "An error has occurred",
        message: "The payment could not be checked. Please contact our support",
      },
      ERROR_API_PAYMENT_REJECTED: {
        headline: "Payment rejected",
        message: "The payment has been rejected. Please try again later",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL_INVALIDATION: {
        headline: "Please check your input",
        message: "The e-mail is invalid",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL_EMPTY: {
        headline: "Please check your input",
        message: "The e-mail must not be empty",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL_TAKEN: {
        headline: "Please check your input",
        message: "The e-mail is already registered",
      },
      ERROR_API_VALIDATION_ERROR_EMAIL: {
        headline: "Please check your input",
        message: "A problem occurred while validating the e-mail",
      },
      ERROR_API_VALIDATION_ERROR_PHONE_INVALIDATION: {
        headline: "Please check your input",
        message: "The phone number is invalid.",
      },
      ERROR_API_VALIDATION_ERROR_PHONE_EMPTY: {
        headline: "Please check your input",
        message: "The phone number must not be empty",
      },
      ERROR_API_VALIDATION_ERROR_PHONE_TAKEN: {
        headline: "Please check your input",
        message: "The phone number is already registered",
      },
      ERROR_API_VALIDATION_ERROR_PHONE: {
        headline: "Please check your input",
        message: "A problem occurred while validating the phone number",
      },
      ERROR_API_NOT_ACCEPTABLE: {
        headline: "error info from Sepa",
        message: "Slimpay order NOT successfully completed",
      },
      ERROR_API_RECORD_NOT_FOUND: {
        headline: "Record not found",
        message: "The record identified could not be found.",
      },
    },
  },
};

export const CatalogueContext = createContext(defaultCatalogue);

export default function CatalogueProvider({
  children,
  catalogue,
  locale = "de",
}) {
  if (!(locale in defaultCatalogue)) {
    locale = "de";
  }
  return (
    <CatalogueContext.Provider
      value={{ ...defaultCatalogue[locale], ...catalogue }}
    >
      {children}
    </CatalogueContext.Provider>
  );
}
