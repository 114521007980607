import React from 'react';
import Image from 'components/Image';
import WrapGrid from 'components/WrapGrid';
import ListItem from './ListItem';

import {
  BenefitsImagesStyled,
  ListItemImage,
  ListItemText,
  HeadlineStyled,
  WrapperStyled
} from './styles';

export default function BenefitsImages({ listItem, headline }) {
  return(
    <BenefitsImagesStyled>
      <HeadlineStyled>{headline}</HeadlineStyled>
      <WrapGrid>
        <WrapperStyled>
          {listItem.map(({ listItem, listItemImage }, idx) => (
            <ListItem key={`listItem-${listItem}-${idx}`}>
              <ListItemImage>
                <Image
                  fluid={listItemImage[0]}
                  aspectRatio={1 / 1}
                  // hasBoxShadow
                />
              </ListItemImage>
              <ListItemText>
                {listItem}
              </ListItemText>
            </ListItem>
          ))}
        </WrapperStyled>
      </WrapGrid>
    </BenefitsImagesStyled>
  )
}
