import React, { createContext, useState, useContext } from "react";
import SelectStudio from "../SelectStudio";
import useApi from "../useApi/useApi";
import useFeedback from "../useFeedback/useFeedback";
import SelectCountry from "../SelectCountry";
import InputName from "../InputName";
import InputPhone from "../InputPhone";
import MessageBox from "../MessageBox";
import ButtonSubmit from "../ButtonSubmit";
import CheckAgree from "../CheckAgree";
import InputEmail from "../InputEmail";
import Loader from "../Loader";
import CatalogueProvider, { CatalogueContext } from "../Catalogue/Catalogue";
import "../EvoForm.css";

export default function TrialForm(props) {
  const {
    showMessage,
    setMessage,
    handleOkayClickFeedback,
    handleError,
    handleSuccess,
    message,
  } = useFeedback();

  const { handleSubmitTrial, sendFormInProgress, setSendFormInProgress } =
    useApi({
      onSuccessApi: props.onSuccess,
      redirectSuccess: props.redirectSuccess,
      showMessage,
      handleError,
      locale: props.locale,
      handleSuccess,
    });

  const catalogue = useContext(CatalogueContext);

  function handleOkayClick(e) {
    setSendFormInProgress(false);
    handleOkayClickFeedback(e);
  }

  return (
    <CatalogueProvider catalogue={props.catalogue} locale={props.locale}>
      <CatalogueContext.Consumer>
        {(catalogue) => (
          <form onSubmit={(e) => handleSubmitTrial(e)} className="evo-form">
            <div className="evo-form__row evo-form__row--2">
              <InputName />
            </div>
            <div className="evo-form__row">
              <InputEmail />
            </div>
            <div className="evo-form__row evo-form__row--2">
              <SelectCountry />
              <InputPhone />
            </div>
            <SelectStudio
              clubId={props.clubId}
              clubName={props.clubName}
              city={props.city}
            />
            <div className="evo-form__row evo-form__row--2">
              <CheckAgree agbText={catalogue.agbTrial} />
              {sendFormInProgress && <Loader />}
              <ButtonSubmit
                sendFormInProgress={sendFormInProgress}
                buttonText={catalogue.sendTrial}
              />
            </div>
            {message && (
              <MessageBox
                message={message}
                handleOkayClick={(e) => handleOkayClick(e)}
              />
            )}
          </form>
        )}
      </CatalogueContext.Consumer>
    </CatalogueProvider>
  );
}
