import { useState, useContext } from "react";
import { CatalogueContext } from "../Catalogue/Catalogue";

export default function useFeedback() {
  const [message, setMessage] = useState(false);

  const catalogue = useContext(CatalogueContext);

  function handleOkayClickFeedback(e) {
    setMessage(false);
  }

  function showMessage(type, headline, message, hideOkButton) {
    setMessage({
      type: type,
      headline: headline,
      message: message,
      hideOkButton: hideOkButton,
    });
  }

  function handleSuccess({ json, data }) {
    data.jsonApiResponse = json;
    // if (navigator && navigator.userAgent) data.userAgent = navigator.userAgent;
    // if (window.location) data.url = window.location;

    // if (typeof window.gtag === "function") {
    //   window.gtag("event", "conversion", {
    //     send_to: `${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}/VvcXCPXf0a4BEOOf2poD`,
    //   });
    // }
    // console.log("handlesuccess");
    // console.log({
    //   window,
    //   dataLayer: window?.dataLayer,
    //   push: window?.dataLayer?.push,
    //   isFunction: typeof window?.dataLayer?.push === "function",
    // });

    if (typeof window?.dataLayer?.push === "function") {
      window.dataLayer.push({ event: "conversion" });
    }

    if (typeof window.ga === "function") {
      window.ga(
        "send",
        "event",
        "Form Events",
        "SUCCESS",
        JSON.stringify(data)
      );
    }
  }

  function handleError({ code: errorCode, data: data }) {
    let hideOkButton = false;
    if (
      [
        "ERROR_API_PAYMENT_NOVALIDATION",
        "ERROR_API_PAYMENT_CREDIT_CARD_UNLISTED_ERROR",
        "ERROR_API_PAYMENT_TIMEOUT",
      ].indexOf(errorCode) > -1
    ) {
      hideOkButton = true;
    }

    let messageCode = "ERROR_GENERAL";

    if (errorCode in catalogue.de.error) {
      messageCode = errorCode;
    }

    setMessage({
      type: "error",
      headline: catalogue.de.error[messageCode].headline,
      message: catalogue.de.error[messageCode].message,
      hideOkButton,
    });

    console.info({ errorCode, data });

    if (typeof window.ga === "function") {
      if (navigator && navigator.userAgent)
        data.userAgent = navigator.userAgent;
      if (window.location) data.url = window.location;
      let gaErrorDescription =
        "errorCode: " + errorCode + ", sendData: " + JSON.stringify(data);
      window.ga("send", "exception", gaErrorDescription, false);
      window.ga(
        "send",
        "event",
        "Form Errors",
        errorCode,
        JSON.stringify(data)
      );
    }
  }

  return {
    showMessage: showMessage,
    setMessage: setMessage,
    handleError: handleError,
    handleSuccess: handleSuccess,
    handleOkayClickFeedback: handleOkayClickFeedback,
    message: message,
  };
}
