import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Headline from "components/Headline";

export const MembershipStyled = styled("div")(
  {
    position: "relative",
  },
  (props) => ({
    background: props.theme.sectionMembership,
  }),
  ({ padding }) =>
    padding && {
      padding: "30vh 0 20px",
      [media("tabletFluid")]: {
        padding: "30vh 0 40px",
      },
      [media("laptop")]: {
        padding: "30vh 0 60px",
      },
    }
);

export const BgStyled = styled("div")({
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
  zIndex: 0,
});

export const MembershipInnerStyled = styled("div")({
  position: "relative",
  zIndex: 2,
});

export const MembershipWrapStyled = styled("div")({
  position: "relative",
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
});

export const HeadlineStyled = styled(Headline)({
  lineHeight: "42px",
  textTransform: "uppercase",
  fontWeight: 900,
  maxWidth: "100%",
  textAlign: "center",
  fontSize: "34px",
  color: "#ffffff",
  margin: "0px 0 30px",
  letterSpacing: "1px",
  display: "block",
  width: "100%",
});
