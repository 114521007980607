import React from "react";
import Headline from "components/Headline";
import { parseAsText } from "utils/parser";
import { cx } from "utils/emotion";
import TrialForm from "./evo-forms/TrialForm";
import SignupForm from "./evo-forms/SignupForm";
import SwitchTheme from "components/SwitchTheme";

import {
  EvoFormStyled,
  StyledFormWrapper,
  FormStyled,
  StyledDiv,
} from "./FormStyled";

export default function EvoForm({
  headline,
  headlineTag,
  city,
  alignment,
  type,
  code,
  theme,
  pageContext,
  onSuccess,
  apiId,
  firstStepIndex,
  curStepIndex,
  fullWidth,
  onStepChange,
  clubName,
  clubSelection,
  layout,
}) {
  const typeShort = type === "trialForm" ? "trial" : "signup";
  const baseUrlCurrent = pageContext?.baseUrlCurrent;

  const locale = pageContext.language;

  const formOptions = {
    default: {
      redirectsuccess: `${baseUrlCurrent}${typeShort}-welcome`,
      city: null,
    },
    duesseldorf: {
      redirectsuccess: `${baseUrlCurrent}${typeShort}-welcome/dd`,
      city: "D",
    },
    hamburg: {
      redirectsuccess: `${baseUrlCurrent}${typeShort}-welcome/hh`,
      city: "HH",
    },
    berlin: {
      redirectsuccess: `${baseUrlCurrent}${typeShort}-welcome/be`,
      city: "B",
    },
  };

  return (
    <SwitchTheme name={theme}>
      <EvoFormStyled>
        <FormStyled>
          {headline && (
            <StyledDiv alignment={alignment}>
              <Headline margin="0 0 30px" element={headlineTag || undefined}>
                {parseAsText(headline)}
              </Headline>
            </StyledDiv>
          )}
          <StyledFormWrapper
            id="evoFormWrapper"
            themeName={theme}
            alignment={alignment}
            fullWidth={fullWidth}
          >
            {type === "trialForm" && (
              <TrialForm
                onSuccess={onSuccess}
                redirectSuccess={formOptions?.[city]?.redirectsuccess}
                city={formOptions?.[city]?.city}
                locale={locale}
                clubId={apiId}
                clubName={clubName}
                layout={layout}
              />
            )}
            {type === "signupForm" && (
              <SignupForm
                firstStepIndex={firstStepIndex}
                curStepIndex={curStepIndex}
                onStepChange={onStepChange}
                clubId={apiId}
                clubName={clubName}
                onSuccess={() => onSuccess(true)}
                redirectSuccess={formOptions?.[city]?.redirectsuccess}
                code={code}
                city={formOptions?.[city]?.city}
                locale={locale}
                // api="net"
              />
            )}
          </StyledFormWrapper>
        </FormStyled>
      </EvoFormStyled>
    </SwitchTheme>
  );
}
