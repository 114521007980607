import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function MessageBox({message, handleOkayClick}) {
  const catalogue = useContext(CatalogueContext);

  if(!message) {
    return null
  } else {
    return(<div className={'evo-form__info evo-form__info--'+message.type}>
      <div className="evo-form__info-content">
        <h2 className="evo-form__info-content__h2">{message.headline}</h2>
        <p className="evo-form__info-content__p">{message.message}</p>
        {!message.hideOkButton && <button onClick={handleOkayClick} className="evo-form__info-content-button">{catalogue.messageBoxClose}</button>}
      </div>
    </div>)
  }
}
