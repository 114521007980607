import React from "react";
import PropTypes from "prop-types";
import { parseAsText } from "utils/parser";
import {
  ToggleStyled,
  ToggleInnerStyled,
  TabStyled,
  TabLinkStyled,
} from "./styles";

const Toggle = ({ tabs }) =>
  tabs.length > 1 && (
    <ToggleStyled>
      <ToggleInnerStyled>
        {tabs.map((tab) =>
          tab.tabActive ? (
            tab.sectionFormsTabLink.url ? (
              <TabLinkStyled
                key={tab.sectionFormsTabSlug}
                active={tab.sectionFormsSelected}
                to={
                  tab.sectionFormsTabLink?.entry?.fullUri ||
                  tab.sectionFormsTabLink?.url
                }
              >
                {parseAsText(tab.sectionFormsTabTitle)}
              </TabLinkStyled>
            ) : (
              <TabStyled
                key={tab.sectionFormsTabSlug}
                active={tab.sectionFormsSelected}
                type="button"
              >
                {parseAsText(tab.sectionFormsTabTitle)}
              </TabStyled>
            )
          ) : null
        )}
      </ToggleInnerStyled>
    </ToggleStyled>
  );

Toggle.propTypes = {
  tabs: PropTypes.array,
};

Toggle.defaultProps = {
  tabs: [],
};

export default Toggle;
