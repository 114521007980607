import { styled } from "utils/emotion";

export const DoneViewWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
});
export const Text = styled("div")({
  fontSize: ".9rem",
  color: "white",
  textAlign: "center",
  maxWidth: "450px",
  marginTop: "3rem",
  marginBottom: "3rem",
  // override Redactor style
  p: {
    fontWeight: 600,
  },
});
export const Content = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: "100%",
});

export const IconWrapper = styled("div")({
  textAlign: "center",
  background: "white",
  padding: 50,
  margin: 50,
  borderRadius: 1000,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: 0,
  boxShadow: "0px 5px 30px rgba(0,0,0,0.6)",
});
