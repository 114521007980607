import React, { useState }  from 'react'
import Select from 'react-select';

export default function SelectBox({name, options, placeholder, onChange, defaultValue}) {
  const [selectedOption, setSelectedOption] = useState(null)

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    if(onChange) onChange(selectedOption);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      // color: state.isSelected ? 'red' : 'blue',
      // padding: 20,
      height: 56,
      background: state.isSelected ? '#f0f0f0' : '#ffffff',
      color: 'black',
      padding: 18,
      transition: 'all 200ms ease-in-out',
      '&:hover': {
        background: !state.isSelected && '#03afaa',
        color: !state.isSelected && 'white',
        cursor: 'pointer'
      }
    }),
    input: (provided) => ({
      ...provided,
      height: 40,
      // const color = state.isFocused ? 'black':'white';'
    }),
    control: (provided, state) => ({
      ...provided,
      height: 56,
      border: 0,
      borderRadius: 0,
      borderBottom: '1px solid rgba(255,255,255,0.5)',
      background: 'transparent',
      transition: 'all 200ms ease-out',
      fontWeight: 'bold',
      fontFamily: 'Open Sans,sans-serif',
      cursor: 'pointer',
      '&:hover': {
        border: state.isFocused && 0,
        borderBottom: '1px solid white'
      }
    }),
    container: (provided, state) => ({
      ...provided,
      // background: state.isFocused ? 'white':'transparent',
      transition: 'all 200ms ease-out',
      border: 0,
      'singleValue': {
        color: 'black'
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'rgba(255, 255, 255, 0.5)',

      '&:hover': {
        color: 'white'
      }
    }),
    indicatorsContainer: (provided) => ({
      ...provided,

      '&:hover': {
        color: 'white'
      }
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      opacity: 0
    }),
    menu: (provided) => ({
      ...provided,
      background: '#ffffff',
      margin: 0,
      color: 'black',
      borderRadius: 0,
      overflow: 'hidden',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      boxShadow:'0px 10px 20px rgba(0,0,0,0.5)',
      // border: '1px solid rgba(255,255,255,0.08)',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,

    }),
    menuPortal: (provided) => ({
      ...provided,

      // background: 'red',
      // border: '1px solid red',
    }),
    placeholder: (provided) => ({
      ...provided,
      textTransform: 'uppercase',
      marginLeft: '-6px',
      fontWeight: 'bold',
      color: 'white',
      fontFamily: 'Open Sans,sans-serif',
    }),
    valueContainer: (provided) => ({
      ...provided,

      background: 'transparent',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'white',
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      marginLeft: '-6px'
    })
  }

  return(
    <div className="evo-form__input-wrap">
      <Select
        name={name}
        styles={customStyles}
        value={selectedOption || defaultValue}
        options={options}
        onChange={handleChange}
        autocomplete="off"
        placeholder={placeholder}
        // menuIsOpen
        isSearchable={false}
        // inputProps={{autoComplete: 'none', autoCorrect: 'off', spellCheck: 'off' }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: 'transparent',
          },
        })}
      />
    </div>
  )

}
