import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

export const ProgressStepWrapper = styled('div')({
  display: 'block',
  margin: '10px',

  [media('laptop')]: {
    display: 'inline-block',
  }
});
export const ProgressStepName = styled('div')(
  {
    fontWeight: '600',
    fontSize: '.7rem',
    position: 'relative',
    marginRight: '15px',
    marginLeft: '15px',
    textTransform: 'uppercase',
    cursor: 'pointer', // appear clickable
    textIndent: 30,
    [media('laptop')]: {
      textIndent: 0
    },
    // dot
    '&:after': {
      content: '" "',
      width: '11px',
      height: '11px',
      borderRadius: '5px',
      position: 'absolute',
      zIndex: 1, // put above the progress line
      transition: 'color 200ms',
      left: 0,
      bottom: 3,
      [media('laptop')]: {
        left: 'calc(50% - 7px)',
        bottom: '-14px',
      }
    },
  },
  props => ({
    color: props.check ? props.theme.borderColorTertiary : '#b3b3b3',
    transition: 'color 400ms',
    '&:after': {
      backgroundColor: props.check ? props.theme.backgroundTertiary : '#b3b3b3',
      transition: 'color 400ms',
    },
  }),
);

export const ProgressLine = styled('div')(
  {
    width: '2px',
    height: 'calc(100% - 15px)',
    position: 'absolute',
    backgroundColor: '#b3b3b3',
    top: '7px',
    left: '30px',
    [media('laptop')]: {
      width: 'calc(100% - 85px)', // hide edges(start/end on line) behind StepName 'after' pseudo element
      height: '2px',
      bottom: '0px',
      left: 'initial',
      top: 'auto',
    }
  },
  props => ({
    // progress bar
    '&:after': {
      content: '" "',
      position: 'absolute',
      backgroundColor: props.theme.backgroundTertiary,
      height: `${props.width}%`,
      width: '2px',
      transition: 'height 200ms',
      willChange: 'height',
      [media('laptop')]: {
        transition: 'width 200ms',
        willChange: 'width',
        height: '2px',
        width: `${props.width}%`,
      }
    },
  }),
);

export const ProgressBarWrapper = styled('div')({
  marginBottom: '1rem',
  marginTop: '1rem',
  position: 'relative',

  [media('tabletFluid')]: {

  },

  [media('laptop')]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
    marginTop: '5rem',
  },
});
export const ProgressBarStepsWrapper = styled('div')({
  [media('laptop')]: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  }
});
