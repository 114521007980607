import ApiHelper from "./ApiHelper";

export default function ApiResponse({ handleError, handleSuccess }) {
  const { devmode } = ApiHelper();

  function handleApiErrorResponseCodes({ errors, data }) {
    errors.forEach((error) => {
      if (error.code == "ERROR_API_NOJSON") {
        handleError({ code: "ERROR_API_NOJSON", data: data });
      } else if (error.code == "NOT_ACCEPTABLE") {
        if (error.details == "error info from Sepa") {
          handleError({ code: "ERROR_API_SEPA_NOT_ACCEPTABLE", data: data });
        } else {
          handleError({
            code: "ERROR_API_UNLISTED_ERROR_NOT_ACCEPTABLE",
            data: data,
          });
        }
      } else if (error.code == "RECORD_NOT_FOUND") {
        handleError({ code: "ERROR_API_RECORD_NOT_FOUND", data: data });
      } else if (error.code == "VALIDATION_ERROR") {
        if (
          error.meta.field == "mobile" ||
          error.meta.field == "mobile_number"
        ) {
          if (error.meta.reason == "invalid") {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_PHONE_INVALID",
              data: data,
            });
          } else if (error.meta.reason == "blank") {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_PHONE_EMPTY",
              data: data,
            });
          } else if (error.meta.reason == "taken") {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_PHONE_TAKEN",
              data: data,
            });
          } else {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_PHONE",
              data: data,
            });
          }
        } else if (error.meta.field == "email") {
          if (error.meta.reason == "invalid") {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_EMAIL_INVALID",
              data: data,
            });
          } else if (error.meta.reason == "blank") {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_EMAIL_EMPTY",
              data: data,
            });
          } else if (error.meta.reason == "taken") {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_EMAIL_TAKEN",
              data: data,
            });
          } else {
            handleError({
              code: "ERROR_API_VALIDATION_ERROR_EMAIL",
              data: data,
            });
          }
        } else {
          handleError({
            code: "ERROR_API_UNLISTED_VALIDATION_ERROR",
            data: data,
          });
        }
      } else if (error.title == "payment declined") {
        handleError({ code: "ERROR_API_PAYMENT_REJECTED", data: data });
      } else {
        handleError({ code: "ERROR_API_UNLISTED_ERROR", data: data });
      }
    });
  }

  function handleResponseTrialMember({ data, json, onSuccess, onResponse }) {
    if (devmode) console.log(json);

    // console.log("handleResponseTrialMember");
    if (json.errors) {
      handleApiErrorResponseCodes({ errors: json.errors, data: data });
    } else {
      if (json.data) {
        onSuccess();
        handleSuccess({ json, data });
      }
    }

    onResponse();
  }

  function handleResponseCreateQuote({ data, json, onSuccess, onResponse }) {
    if (devmode) console.log("handleResponseCreateQuote; Api Returns: ", json);

    const quoteData = {
      current_payment: json.data.attributes.current_payment,
      next_payment: json.data.attributes.next_payment,
      signup_presentation: json.data.attributes.product.signup_presentation,
    };

    if (json.errors) {
      handleApiErrorResponseCodes({ errors: json.errors, data: data });
    } else {
      if (json.data) {
        onSuccess(json);
      }
    }

    onResponse(quoteData);
  }

  function handleResponsePrepareQuote({ data, json, onSuccess, onResponse }) {
    if (devmode) console.log("handleResponsePrepareQuote; Api Returns: ", json);

    if (json.errors) {
      handleApiErrorResponseCodes({ errors: json.errors, data: data });
    } else {
      if (json.data) {
        onSuccess(json);
      }
    }

    onResponse();
  }

  function handleResponseCheckPayment({
    data,
    json,
    paymentType,
    onSuccess,
    onRepeat,
  }) {
    if (devmode) console.log("handleResponseCheckPayment; Api Returns: ", json);

    if (json.errors) {
      handleApiErrorResponseCodes({ errors: json.errors, data: data });
    } else {
      if (json.data) {
        if (paymentType == "CREDIT_CARD") {
          if (json.data.attributes.status == "CREATED") {
            onRepeat();
          } else if (json.data.attributes.status == "ACCEPTED") {
            let paymentInfo = { id: json.data.attributes.id };
            onSuccess(paymentInfo);
          } else if (json.data.attributes.status == "INVALID") {
            handleError({
              code: "ERROR_API_PAYMENT_CREDIT_CARD_INVALID",
              data: data,
            });
          } else {
            handleError({
              code: "ERROR_API_PAYMENT_CREDIT_CARD_UNLISTED_ERROR",
              data: data,
            });
            onRepeat();
          }
        } else if (paymentType == "SEPA") {
          if (json.data.attributes.slimpay_subscriber_id) {
            let paymentInfo = {
              id: json.data.id,
              subscriber_id: json.data.attributes.slimpay_subscriber_id,
            };
            onSuccess(paymentInfo);
          } else {
            handleError({
              code: "ERROR_API_PAYMENT_SEPA_UNLISTED_ERROR",
              data: data,
            });
          }
        }
      } else {
        handleError({ code: "ERROR_API_PAYMENT_NOVALIDATION", data: data });
      }
    }
  }

  function handleResponseEnrollMember({ data, json, onSuccess }) {
    if (devmode) console.log("handleResponseEnrollMember; Api Returns: ", json);

    if (json.errors) {
      handleApiErrorResponseCodes({ errors: json.errors, data: data });
    } else {
      if (json.data) {
        onSuccess();
      }
    }
  }

  return {
    handleResponseTrialMember: handleResponseTrialMember,
    handleResponseCreateQuote: handleResponseCreateQuote,
    handleResponsePrepareQuote: handleResponsePrepareQuote,
    handleResponseCheckPayment: handleResponseCheckPayment,
    handleResponseEnrollMember: handleResponseEnrollMember,
  };
}
