import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function InputBirthdate({}) {
  const catalogue = useContext(CatalogueContext);

  function handleChangeInput(event) {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
  }

  return(
    <div className="evo-form__input-wrap">
      <div className="evo-form__input-birthdate">
        <span className="evo-form__input-birthdate-caption">{catalogue.birthdate}</span>
        <input className="evo-form__input evo-form__input-birthdate-day" pattern="[0-9]{2}" onChange={handleChangeInput} name="birthdate-day" type="text" min="01" max="31" step="1" maxLength="2" placeholder={catalogue.birthdateDay} autoComplete="off" required />
        <input className="evo-form__input evo-form__input-birthdate-month" pattern="[0-9]{2}" onChange={handleChangeInput} name="birthdate-month" type="text" min="01" max="12" step="1" maxLength="2"  placeholder={catalogue.birthdateMonth} autoComplete="off" required />
        <input className="evo-form__input evo-form__input-birthdate-year" pattern="(?:19|20)[0-9]{2}" onChange={handleChangeInput} name="birthdate-year" type="text" min="1900" step="1" maxLength="4"  max={(new Date()).getFullYear()-18} placeholder={catalogue.birthdateYear} autoComplete="off" required />
      </div>
    </div>
  )
}
