import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function InputPhone({}) {
  const catalogue = useContext(CatalogueContext);

  return(
    <div className="evo-form__input-wrap">
      <input className="evo-form__input" name="phone" pattern="^[0-9]*$" type="tel" placeholder={catalogue.mobile} required />
    </div>
  )
}
