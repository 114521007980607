import React from "react";
import WrapGrid from "components/WrapGrid";
import { parseAsText } from "utils/parser";

import {
  HeroFixedStyled,
  ContentStyled,
  TextVisualStyled,
  HeadlineStyled,
} from "./styles";

/**
 * HeroFixed component.
 */
export default function HeroFixed(props) {
  const { textvisual, headline } = props;

  return (
    <HeroFixedStyled>
      <WrapGrid>
        <ContentStyled>
          {textvisual && (
            <TextVisualStyled>{parseAsText(textvisual)}</TextVisualStyled>
          )}
          {headline && (
            <HeadlineStyled element="h1" uppercase>
              {parseAsText(headline)}
            </HeadlineStyled>
          )}
        </ContentStyled>
      </WrapGrid>
    </HeroFixedStyled>
  );
}
