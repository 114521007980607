import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function OptionPayment({}) {
  const catalogue = useContext(CatalogueContext);

  const randId = Math.random().toString(36).substr(2, 9);

  return(
    <div>
      <div className="evo-form__option-row">
        <div className="evo-form__select-wrap">
          <select className="evo-form__select" name="payment_method">
            <option value="CREDIT_CARD">{catalogue.paymentCreditCard}</option>
            <option value="SEPA">{catalogue.paymentSepa}</option>
          </select>
        </div>
      </div>
    </div>
  )

}
