import React from 'react';
import { graphql } from 'gatsby';

import {
  ClubColName,
  ClubColStyled,
  Club,
  ClubLink,
  ClubNameStyled,
  ClubAdressStyled,
  ClubButtonStyled,
  ClubCityImageStyled
} from './ClubsViewStyled';

export default function ClubsCol ({ cityName, cityIcon, clubs, onChoose, signupEnabled, pageContext }) {
  // const { ctaChooseClubText } = data;
  const ctaChooseClubText = pageContext.language === 'en' ? 'choose club' : 'club wählen';
  return (
    <ClubColStyled>
      <ClubCityImageStyled
        objectFit="contain"
        fluid={cityIcon}
        aspectRatio={600/374}
        style={{marginBottom: 20}}
      />  
      <ClubColName>{cityName}</ClubColName>
      {clubs.map(club => {
        if (
          (signupEnabled && !club.code && club.url) ||
          (!signupEnabled && club.url)
        ) {
          return (
            <ClubLink key={`link-${club?.apiId}`} to={club.url}>
              <ClubNameStyled>{club.text}</ClubNameStyled>
              <ClubAdressStyled>{club.address}</ClubAdressStyled>
              <ClubButtonStyled
                themeName="green"
                stretch
                textAlign="center"
                margin="20px 0 5px"
                to={club.url}
                // isNotLink
              >
                {ctaChooseClubText}
              </ClubButtonStyled>
            </ClubLink>
          );
        }
        return (
          <Club
            onClick={() => onChoose? onChoose({club, name}) : null}
            key={`${club?.apiId}`}
          >
            <ClubNameStyled>{club.text}</ClubNameStyled>
            <ClubAdressStyled>{club.address}</ClubAdressStyled>
            <ClubButtonStyled
              themeName="green"
              stretch
              textAlign="center"
              margin="20px 0 5px"
              onClick={() => onChoose? onChoose({club, name}) : null}
              isNotLink
            >
              {ctaChooseClubText}
            </ClubButtonStyled>
          </Club>
        );
      })}
    </ClubColStyled>
  )
}


// export const query = graphql`
//   fragment GlobalCtaClubChoiceQuery on Craft_GlobalsSet {
//     ctaButtons {
//       ctaChooseClubText
//     }
//   }
// `;
