import React from 'react'
import CardsView from '../CardsView';
import ClubsView from '../ClubsView';

const FirstStep = ({
  type,
  items,
  onChoose,
  signupEnabled,
  clubSelection,
  pageContext
}) => {
  if(type === 'clubs') {
    return (
      <ClubsView
        signupEnabled={signupEnabled}
        clubs={items}
        onChoose={choice => onChoose(choice)}
        clubSelection={clubSelection}
        pageContext={pageContext}
      />
    )
  }

  if(type === 'cards') {
    return (
      <CardsView
        signupEnabled={signupEnabled}
        cards={items}
        onChoose={choice => onChoose(choice)}
        pageContext={pageContext}
      />
    )
  }

  return null
}

export default FirstStep
