import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import { colors } from "components/Layout/variables";
import Link from "../../Link";

export const ToggleStyled = styled("div")({
  position: "relative",
  display: "block",
  textAlign: "center",
  marginBottom: 20,
});

export const ToggleInnerStyled = styled("div")(
  {
    display: "inline-flex",
    padding: "3px",

    flexWrap: "wrap",
    backgroundColor: colors.white,

    [media("tabletFluid")]: {
      display: "inline-block",
      padding: "6px 3px",
    },
  },
  (props) => ({ borderRadius: props.theme.borderRadiusPrimary })
);

export const TabStyled = styled("button")(
  {
    display: "block",
    padding: 15,
    borderRadius: "inherit",
    fontSize: "0.94em",
    fontWeight: 900,
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    margin: "3px",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    width: "100%",
    color: "black",

    [media("tabletFluid")]: {
      display: "inline-block",
      margin: "0 3px",
      minWidth: 200,
      width: "auto",
    },

    "&:hover, &:active, &:focus": {
      outline: 0,
    },
  },
  (props) =>
    props.active && {
      color: colors.white,
      backgroundColor: colors.green,

      "&:active, &:focus": {
        backgroundColor: colors.green,
      },
    },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
  })
);

export const TabLinkStyled = styled(Link)(
  {
    display: "block",
    padding: 15,
    borderRadius: "inherit",
    fontSize: "0.94em",
    fontWeight: 900,
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    margin: "3px",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    width: "100%",
    color: "black",

    [media("tabletFluid")]: {
      display: "inline-block",
      margin: "0 3px",
      minWidth: 200,
      width: "auto",
    },

    "&:hover, &:active, &:focus": {
      outline: 0,
    },
  },
  (props) =>
    props.active && {
      color: colors.white,
      backgroundColor: colors.green,

      "&:active, &:focus": {
        backgroundColor: colors.green,
      },
    },
  (props) => ({
    fontFamily: props.theme.fontSecondary,
  })
);
