import React from 'react';
import PropTypes from 'prop-types';

import {
    RibbonStyled,
    TextStyled
  } from './styles';

export default function Ribbon({ text }) {
  return text ? (  
    <RibbonStyled>
        <span className="corner1"></span>
        <TextStyled>{text}</TextStyled>
        <span className="corner2"></span>
    </RibbonStyled>
  ) : null;
}

Ribbon.propTypes = {
  text: PropTypes.string,
};

Ribbon.defaultProps = {
  text: '',
};
