import { styled } from "utils/emotion";

export const SectionStyled = styled("section")(
  {
    // padding: '70px 0',
    position: "relative",
    zIndex: 0,
  },
  ({ theme }) => ({
    // backgroundColor: theme.backgroundInstagram,
  })
);
