import React from "react";
import { graphql } from "gatsby";

import SwitchTheme from "components/SwitchTheme";
import Memberships from "components/Memberships";

import { SectionStyled } from "./FormStyled";

export default function Form({ pageBuilder, pageContext }) {
  const {
    sectionFormsHeadline,
    sectionFormsTextvisual,
    sectionFormsBackgroundImage,
    sectionFormsForm,
    sectionFormsTheme,
    sectionFormBenefits,
    sectionFormBenefitsGallery,
    sectionFormsBenefitsHeadline,
  } = pageBuilder;

  return (
    <SwitchTheme name={sectionFormsTheme}>
      <SectionStyled>
        <Memberships
          header={{
            headline: sectionFormsHeadline,
            textvisual: sectionFormsTextvisual,
            backgroundImage: sectionFormsBackgroundImage,
          }}
          tabs={sectionFormsForm}
          pageContext={pageContext}
          sectionFormBenefits={sectionFormBenefits}
          sectionFormBenefitsGallery={sectionFormBenefitsGallery}
          sectionFormsBenefitsHeadline={sectionFormsBenefitsHeadline}
          optionSignupEnabled
        />
      </SectionStyled>
    </SwitchTheme>
  );
}

export const query = graphql`
  fragment PageBuilderFormQuery on Craft_PageBuilderForm {
    sectionFormsHeadline
    sectionFormsTextvisual
    sectionFormsBenefitsHeadline
    sectionFormBenefits {
      listItem
      listItemImage {
        id
        url
        ...CraftImageFluid
      }
    }
    sectionFormBenefitsGallery {
      galleryImage {
        ...CraftImageFluid
      }
      galleryImageTitle
      galleryImageHeadline
      galleryImageText
    }
    sectionFormsForm {
      id
      sectionFormsTabTitle
      sectionFormsTabLink {
        url
        entry {
          fullUri
        }
      }
      sectionFormsTabSlug
      sectionFormsFormHeadline
      sectionFormsFormHeadlineTag
      trialFormLayout
      tabActive
      sectionFormsFormClubs {
        id
        ... on Craft_PagesPagesClubs {
          locationApiId
        }
      }
      sectionFormsFormType
      sectionFormsSelected
    }
    sectionFormsAlignment
    sectionFormsBackgroundImage {
      id
      url
      ...CraftImageFluid
    }
    sectionFormsTheme
  }
`;
