import React, { useContext } from "react";
import { CatalogueContext } from "../Catalogue/Catalogue";
import SelectBox from "../../../SelectBox";

export default function SelectStudio({
  clubId,
  clubName,
  city,
  onChange,
  handleSelectStudio,
  showStudioText,
}) {
  const catalogue = useContext(CatalogueContext);

  if (clubId) {
    if (handleSelectStudio) handleSelectStudio({ target: { value: clubId } });
    return (
      <div>
        {clubName && showStudioText && <p>{clubName}</p>}
        <input name="club" type="hidden" value={clubId} />
      </div>
    );
  }
  if (city === "D") {
    if (handleSelectStudio)
      handleSelectStudio({
        target: { value: "b4a8da8e-5bdb-4716-96d6-4aa487d30710" },
      });
    return (
      <div>
        {showStudioText && <p>DÜSSELDORF Le Flair</p>}
        <input
          name="club"
          type="hidden"
          value={"b4a8da8e-5bdb-4716-96d6-4aa487d30710"}
        />
      </div>
    );
  }
  if (city === "B") {
    if (handleSelectStudio)
      handleSelectStudio({
        target: { value: "13cbafa0-8b29-4aa8-bed7-34e972caa165" },
      });
    return (
      <div>
        {showStudioText && <p>BERLIN Mitte</p>}
        <input
          name="club"
          type="hidden"
          value={"13cbafa0-8b29-4aa8-bed7-34e972caa165"}
        />
      </div>
    );
  }
  if (city === "HH-ST") {
    if (handleSelectStudio)
      handleSelectStudio({
        target: { value: "a2a3f7b2-2f6b-4d57-9d7d-be66a04c66c0" },
      });
    return (
      <div>
        {showStudioText && <p>HAMBURG Stadthausbrücke</p>}
        <input
          name="club"
          type="hidden"
          value={"a2a3f7b2-2f6b-4d57-9d7d-be66a04c66c0"}
        />
      </div>
    );
  }
  if (city === "HH-SC") {
    if (handleSelectStudio)
      handleSelectStudio({
        target: { value: "474e2585-338e-4377-a832-14dbd29a2dd9" },
      });
    return (
      <div>
        {showStudioText && <p>HAMBURG Schanze</p>}
        <input
          name="club"
          type="hidden"
          value={"474e2585-338e-4377-a832-14dbd29a2dd9"}
        />
      </div>
    );
  }
  if (city === "HH-BL") {
    if (handleSelectStudio)
      handleSelectStudio({
        target: { value: "f572429b-5a84-49b2-a9db-fedcb9180d40" },
      });
    return (
      <div>
        {showStudioText && <p>HAMBURG Blankenese</p>}
        <input
          name="club"
          type="hidden"
          value={"f572429b-5a84-49b2-a9db-fedcb9180d40"}
        />
      </div>
    );
  }
  if (city === "HH") {
    const options = [
      {
        value: "f572429b-5a84-49b2-a9db-fedcb9180d40",
        label: "HAMBURG Blankenese",
      },
      {
        value: "474e2585-338e-4377-a832-14dbd29a2dd9",
        label: "HAMBURG Schanze",
      },
      {
        value: "a2a3f7b2-2f6b-4d57-9d7d-be66a04c66c0",
        label: "HAMBURG Stadthausbrücke",
      },
    ];

    return (
      <div className="evo-form__row">
        <div className="evo-form__select-wrap">
          <SelectBox
            name="club"
            options={options}
            onChange={onChange}
            required
            placeholder={catalogue.selectClub}
          />
        </div>
      </div>
    );
  }

  const options = [
    { value: "13cbafa0-8b29-4aa8-bed7-34e972caa165", label: "BERLIN Mitte" },
    {
      value: "b4a8da8e-5bdb-4716-96d6-4aa487d30710",
      label: "DÜSSELDORF Le Flair",
    },
    {
      value: "f572429b-5a84-49b2-a9db-fedcb9180d40",
      label: "HAMBURG Blankenese",
    },
    { value: "474e2585-338e-4377-a832-14dbd29a2dd9", label: "HAMBURG Schanze" },
    {
      value: "a2a3f7b2-2f6b-4d57-9d7d-be66a04c66c0",
      label: "HAMBURG Stadthausbrücke",
    },
  ];
  return (
    <div className="evo-form__row">
      <div className="evo-form__select-wrap">
        <SelectBox
          name="club"
          options={options}
          onChange={onChange}
          required
          placeholder={catalogue.selectClub}
        />
      </div>
    </div>
  );
}
