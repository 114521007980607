import React from 'react'
import { graphql } from 'gatsby'
import Card from './Card'

import { CardWrapperStyled } from './styles'

export default function CardsView({
  cards,
  onChoose,
  signupEnabled,
  pageContext,
}) {
  return (
    <CardWrapperStyled>
      {cards.map((card, idx) => (
        <Card
          key={`card-${idx}`}
          signupEnabled={
            signupEnabled || card.membershipOptionCheckout === 'onpage'
          }
          code={card.membershipOptionCode}
          title={card.membershipOptionTitle}
          offerText={card.membershipOptionOfferText}
          offerBadge={card.membershipOptionOfferBadge}
          bulletImage={card.membershipOptionBulletImage}
          description={card.membershipOptionDescription}
          highlight={card.membershipOptionHighlight}
          link={card.membershipOptionLink}
          price={card.membershipOptionPrice}
          ribbonText={card.membershipOptionRibbon}
          priceCurrency={card.membershipOptionPriceCurrency}
          priceTimeUnit={card.membershipOptionPriceTimeunit}
          quantity={card.membershipOptionQuantity}
          signupSuccessText={card.membershipOptionSuccessText}
          text={card.membershipOptionText}
          timeUnit={card.membershipOptionTimeunit}
          scarcity={card.scarcity}
          onChoose={onChoose}
          pageContext={pageContext}
        />
      ))}
    </CardWrapperStyled>
  )
}
