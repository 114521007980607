import React, { useContext, useEffect } from "react";
import { CatalogueContext } from "../Catalogue/Catalogue";
import DebounceInput from "react-debounce-input";

export default function InputCode({ onChange, value, disabled, code }) {
  const catalogue = useContext(CatalogueContext);
  const isClient = typeof window === "object";

  useEffect(() => {
    var url = new URL(window.location.href);
    var code = code || url.searchParams.get("code") || "";
    if (code) onChange({ target: { value: code } });
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      <div className="evo-form__input-wrap">
        <label>Aktionscode</label>
        <DebounceInput
          onChange={onChange}
          className="evo-form__input"
          type="text"
          name="code"
          autoComplete="off"
          disabled={disabled}
          value={value}
          placeholder={catalogue.code}
          debounceTimeout={1000}
        />
      </div>
    </>
  );
}
