import React, { useState } from "react";
import Icon from "components/Icon";
import Survey from "../Survey";
import { Content, DoneViewWrapper, IconWrapper, Text } from "./styled";

const DoneView = ({ finalTextContent, survey, email }) => {
  const [surveyFinished, setSurveyFinished] = useState(false);
  return (
    <DoneViewWrapper>
      <Content>
        <Text>{finalTextContent}</Text>
        {surveyFinished ? (
          <IconWrapper>
            <Icon
              type="checkmark"
              width="100"
              height="100"
              fillPrimary="#02b0ab"
              currentColor={false}
            />
          </IconWrapper>
        ) : (
          <Survey
            survey={survey}
            onFinish={() => setSurveyFinished(true)}
            email={email}
          />
        )}
      </Content>
    </DoneViewWrapper>
  );
};

export default DoneView;
