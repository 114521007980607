import React, { useState, useEffect } from "react";
import Form from "../Form";
import DoneView from "../DoneView";
import Process from "../Process";
import FirstStep from "./FirstStep";
import ProgressBar from "../Progressbar";
import { mapCity, trackEvent } from "./Helper";
import { HeadlineStyled } from "./styled";
import ApiHelper from "../../EvoForm/evo-forms/useApi/ApiHelper";

const cardChoiceDefault = {
  code: "",
  formSuccessText: "",
};

const { scrollToForm } = ApiHelper();

const clubChoiceDefault = {
  name: "",
  club: {
    name: "",
    // https://www.reddit.com/r/FanTheories/comments/19botr/the_meaning_of_life_the_universe_and_everything/
    apiId: null,
    code: "",
    url: "",
    trialFormSuccessText: "",
  },
};

export default function RegistrationProcess({
  headline,
  city: presetCity,
  sectionFormsType,
  pageContext,
  clubs,
  memberships,
  survey,
  clubSelection,
  layout,
}) {
  const [viewIndex, setViewIndex] = useState(layout === "oneStep" ? 1 : 0);
  const [progressIndex, setProgressIndex] = useState(0);
  const [formType, setFormType] = useState(sectionFormsType);
  const [cardChoice, setCardChoice] = useState(cardChoiceDefault);
  const [clubChoice, setClubChoice] = useState(clubChoiceDefault);
  const [email, setEmail] = useState(null);
  const [signupEnabled, setSignupEnabled] = useState(false);

  const trialViews =
    pageContext.language === "en"
      ? layout === "twoSteps"
        ? [
            { name: "Clubs", view: 0 },
            { name: "Your Info", view: 1 },
            { name: "Ready", view: 2 },
          ]
        : [
            { name: "Your Info", view: 1 },
            { name: "Ready", view: 2 },
          ]
      : layout === "twoSteps"
      ? [
          { name: "Clubs", view: 0 },
          { name: "Your Info", view: 1 },
          { name: "Ready", view: 2 },
        ]
      : [
          { name: "Deine Daten", view: 1 },
          { name: "Fertig", view: 2 },
        ];

  // views are different to the steps, because there are multiple views that are
  // handled within the EvoForm Component
  const signupViews =
    pageContext.language === "en"
      ? [
          { name: "Abo", view: 0 },
          { name: "Clubs", view: 1 },
          { name: "Your Info", view: 1 },
          { name: "Payment Info", view: 1 },
          { name: "Payment Validation", view: 1 },
          { name: "Ready", view: 2 },
        ]
      : [
          { name: "Abo", view: 0 },
          { name: "Clubs", view: 1 },
          { name: "Deine Daten", view: 1 },
          { name: "Zahlungsdaten", view: 1 },
          { name: "Zahlungsbestätigung", view: 1 },
          { name: "Fertig", view: 2 },
        ];

  // Reset all
  if (formType !== sectionFormsType) {
    setFormType(sectionFormsType);
    setViewIndex(0);
    setProgressIndex(0);
    setCardChoice(cardChoiceDefault);
    setClubChoice(clubChoiceDefault);
  }

  // ***************************
  // Formtype dependent stuff
  // ***************************
  const choiceType = formType === "signupForm" ? "cards" : "clubs";
  const choiceItems = choiceType === "cards" ? memberships : clubs;

  const city =
    choiceType === "cards"
      ? presetCity || null
      : clubChoice.name
      ? mapCity(clubChoice.name)
      : null;

  const finalTextContent =
    choiceType === "cards"
      ? cardChoice.formSuccessText || null
      : clubChoice.club.trialFormSuccessText || null;

  const views =
    choiceType === "cards" || clubChoice.club.code ? signupViews : trialViews;

  const trackStep = () => {
    trackEvent(formType, progressIndex, views[progressIndex].name);
  };

  // ***************************
  // Progress handling
  // ***************************
  const showNextView = () => {
    setTimeout(() => {
      scrollToForm();
    }, 500);
    setViewIndex(viewIndex + 1);
    setProgressIndex(progressIndex + 1);
    trackStep();
  };

  const showLastView = (email) => {
    setViewIndex(views[views.length - 1].view);
    setProgressIndex(views.length - 1);
    setEmail(email);
    trackStep();
  };

  const showProgress = (toIndex) => {
    setProgressIndex(toIndex);
    trackStep();
  };

  const handleResetView = (toIndex) => {
    // go back but not forward
    // signup form process step changing is hard to handle due to iframe and payment
    if (formType === "trialForm" && toIndex < progressIndex) {
      setViewIndex(views[toIndex].view);
      setProgressIndex(toIndex);
      trackStep();
    }
    // careful!! we have views and steps that differ
  };

  const handleChoice = (choice) => {
    if (formType === "signupForm") {
      setCardChoice(choice);
    } else {
      setClubChoice(choice);
    }
    showNextView();
    return false;
  };

  useEffect(() => {
    setSignupEnabled(window.location.search === "?signup=enabled");
    if (formType === "signupForm") {
      const url = new URL(window.location.href);
      const code = url.searchParams.get("code") || "";
      if (code !== "") setTimeout(() => handleChoice({ code }), 0);
    }
  }, []);

  return (
    <section
      style={{
        maxWidth: formType === "trialForm" ? "890px" : null,
        minHeight: layout === "twoSteps" ? "100vh" : null,
        margin: "0 auto",
      }}
    >
      {headline && <HeadlineStyled>{headline}</HeadlineStyled>}

      {(cardChoice.code || formType === "trialForm") && layout === "twoSteps" && (
        <ProgressBar
          // paints visited and current view(s) in blue color
          onProcessPointClick={(index) => handleResetView(index)}
          views={views}
          curViewIndex={progressIndex}
        />
      )}

      <Process
        // index of current visible view
        curViewIndex={viewIndex}
      >
        {layout === "twoSteps" && (
          <FirstStep
            signupEnabled={signupEnabled}
            type={choiceType}
            items={choiceItems}
            onChoose={(choice) => handleChoice(choice)}
            clubSelection={clubSelection}
            pageContext={pageContext}
          />
        )}

        <Form
          code={clubChoice.club.code || cardChoice.code || null}
          onStepChange={(step) => showProgress(1 + step)}
          onSuccess={(email) => showLastView(email)}
          pageContext={pageContext}
          type={clubChoice?.club?.code ? "signupForm" : formType} // <--- some clubs don't have a real trial form but a signup with code (e.g. Berlin)
          apiId={clubChoice.club.apiId || null}
          fullWidth={!!clubChoice.club.code || formType === "signupForm"}
          city={city || null}
          clubName={clubChoice.club.name || null}
          clubSelection={clubSelection}
          layout={layout}
        />

        <DoneView
          finalTextContent={finalTextContent}
          survey={survey}
          email={email}
        />
      </Process>
    </section>
  );
}
