import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function TextAgree({}) {
  const catalogue = useContext(CatalogueContext);

  return(
    <div>
      <p className="evo-form__text">{catalogue.agbSignup} <a href="{catalogue.agbLink}" target="_blank">{catalogue.agbLinktext}</a></p>
    </div>
  )
}
