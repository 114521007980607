import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import Link from "../../Link";
import Button from "../../Button";
import Image from "components/Image";

export const ClubCityImageStyled = styled(Image)({
  marginTop: 40,
  maxWidth: 200,

  [media("laptop")]: {
    marginTop: 0,
    // maxWidth: "100%",
  },
});

export const ClubColWrapper = styled("div")({
  display: "flex",
  flexFlow: "wrap",
  justifyContent: "space-between",
  // marginBottom: '1.4rem',
});

export const ClubColStyled = styled("div")({
  width: "100%",

  [media("laptop")]: {
    display: "block",
    width: "calc(33% - 20px)",
  },
});

export const ClubButtonStyled = styled("div")(
  {
    textAlign: "center",
    border: "2px solid #01b0ab",
    color: "#01b0ab",

    padding: "10px",
    margin: "20px 0px 5px",
    fontSize: "15px",
    textTransform: "uppercase",

    "&:hover": {
      backgroundColor: "#01b0ab",
      color: "white",
    },
  },
  (props) => ({
    borderRadius: props.theme.borderRadiusPrimary,
  })
);

export const ClubColName = styled("div")(
  {
    width: "100%",
    fontSize: "1.3rem",
    fontWeight: "900",
    letterSpacing: "1px",
    marginBottom: "2rem",
    textTransform: "uppercase",
  },
  ({ theme }) => ({
    color: theme.borderColorTertiary,
  })
);

export const Club = styled("div")(
  {
    display: "inline-block",
    padding: "15px",
    color: "black",
    fontWeight: 600,
    borderRadius: 5,
    fontSize: "1rem",
    cursor: "pointer",
    marginBottom: "35px",
    backgroundColor: "white",
    marginRight: "10px",

    [media("laptop")]: {
      display: "block",
      width: "100%",
    },
  }
  // ({ theme }) => ({
  //   backgroundColor: theme.backgroundTertiary,
  //   '&:hover': {
  //     backgroundColor: theme.greenDarker,
  //   },
  // }),
);

export const ClubLink = styled(Link)(
  {
    display: "inline-block",
    borderRadius: 4,
    padding: "15px",
    color: "black",
    fontWeight: 600,
    fontSize: "1rem",
    cursor: "pointer",
    marginBottom: "35px",
    backgroundColor: "white",
    marginRight: "10px",

    [media("laptop")]: {
      width: "100%",
    },
  }
  // ({ theme }) => ({
  //   backgroundColor: theme.backgroundTertiary,
  //   '&:hover': {
  //     backgroundColor: theme.greenDarker,
  //   },
  // }),
);

export const ClubNameStyled = styled("div")({
  fontWeight: 700,
});

export const ClubAdressStyled = styled("div")({
  fontSize: 12,
});
