import React from 'react';
import { graphql } from 'gatsby';
import ClubsCol from './ClubsCol';

import {
  ClubColWrapper
} from './ClubsViewStyled';

const getCities = (clubs, clubSelection) => {
  const cities = {}
  const availableClubs = []

  clubSelection.map((club) => availableClubs.push(club.id))

  clubs.map((club) => {
    const cityName = club.locationCity[0].title
    const cityIcon = club.locationCity[0].cityIcon[0]
    const result = cityName in cities
    const clubDetails = {
      name: club.title,
      address: club.locationAddress,
      apiId: club.locationApiId,
      code: club.locationCode,
      text: club.locationBoxText,
      url: club.locationExternalLink.url,
      trialFormSuccessText: club.locationTrialSuccessText,
    }

    const city = {
      cityIcon: cityIcon, 
      cityName: cityName, 
      clubs: [clubDetails]
    }

    if(clubSelection.length < 1 || clubSelection && availableClubs.indexOf(club.id) > -1) {
      if(!result) cities[cityName] = city
      else cities[cityName].clubs.push(clubDetails)
    }
    return cities
  })

  return cities
}

export default function ClubsView({ onChoose, clubs, signupEnabled, clubSelection, pageContext }) {
  const cities = getCities(clubs, clubSelection)

  return (
    <ClubColWrapper>
      {Object.keys(cities).map((city) => (
        <ClubsCol
          key={`club-${city}`}
          cityName={city}
          clubs={cities[city].clubs}
          onChoose={club => (onChoose ? onChoose(club) : null)}
          signupEnabled={signupEnabled}
          cityIcon={cities[city].cityIcon}
          pageContext={pageContext}
        />
      ))}
    </ClubColWrapper>
  );
}

export const query = graphql`
  fragment MembershipClubQuery on Craft_MembershipTabCardsMembershipClub {
    apiId
    signinConfirmationText
    clubTrialSignupFormCode
    clubAddress {
      locationZip
      locationCity {
        id
      }
      locationAddress
    }
    membershipClubBoxText
    externalLink {
      url
      entry {
        fullUri
      }
    }
  }
`;
