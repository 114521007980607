import React, { useContext }  from 'react'
import { CatalogueContext } from '../Catalogue/Catalogue';

export default function InputEmail({}) {
  const catalogue = useContext(CatalogueContext);

  return(
    <div className="evo-form__input-wrap">
      <input className="evo-form__input" name="email" type="email" placeholder={catalogue.email} autoComplete="off" required />
    </div>
  )
}
