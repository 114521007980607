import React, { createContext, useEffect, useState, useContext } from "react";
import SelectStudio from "../SelectStudio";
import useApi from "../useApi/useApi";
import useFeedback from "../useFeedback/useFeedback";
import SelectCountry from "../SelectCountry";
import SelectGender from "../SelectGender";
import InputName from "../InputName";
import InputCode from "../InputCode";
import InputBirthdate from "../InputBirthdate";
import InputPassword from "../InputPassword";
import InputPhone from "../InputPhone";
import InputAddress from "../InputAddress";
import OptionPayment from "../OptionPayment";
import OptionNewsletter from "../OptionNewsletter";
import MessageBox from "../MessageBox";
import ButtonSubmit from "../ButtonSubmit";
import TextAgree from "../TextAgree";
import InputEmail from "../InputEmail";
import Quote from "../Quote";
import Loader from "../Loader";
import CatalogueProvider, { CatalogueContext } from "../Catalogue/Catalogue";
import ApiHelper from "../useApi/ApiHelper";
import "../EvoForm.css";

export default function SignupForm({
  code,
  onStepChange,
  redirectSuccess,
  onSuccess,
  catalogue,
  locale,
  clubId,
  clubName,
  city,
  api
}) {
  const [userClubId, setUserClubId] = useState(clubId || false);
  const [userCode, setUserCode] = useState(code || false);

  const {
    showMessage,
    handleError,
    handleOkayClickFeedback,
    message
  } = useFeedback();

  const {
    step,
    getQuote,
    handleStartSignupApi,
    handleContinueSignupApi,
    handleSubmitSignup,
    sendFormInProgress,
    paymentIframeUrl,
    clubSelected,
    quoteData,
    handleOkayClickApi
  } = useApi({
    onSuccessApi: onSuccess,
    redirectSuccess,
    showMessage,
    handleError,
    handleStartPaymentValidation,
    handleCancelPayment,
    locale,
    api
  });

  const { scrollToForm } = ApiHelper();

  function reportProgress(stepIndex) {
    onStepChange ? onStepChange(stepIndex) : null;
  }

  function handleStartSignup(e) {
    scrollToForm();
    handleStartSignupApi(e);
    reportProgress(1);
  }

  function handleContinueSignup(e) {
    let validate = testInputs(".form-2");
    if (validate) {
      scrollToForm();
      handleContinueSignupApi(e);
      reportProgress(2);
    }
  }

  function handleCancelPayment() {
    scrollToForm();
    reportProgress(1);
  }

  function handleStartPaymentValidation() {
    scrollToForm();
    reportProgress(3);
  }

  function handleOkayClick(e) {
    handleOkayClickFeedback(e);
    scrollToForm();
    handleOkayClickApi();
  }

  function handleSelectStudio(e) {
    if (e.preventDefault) e.preventDefault();
    if (userClubId !== e.value) setUserClubId(e.value);
  }

  function handleChangeCode(e) {
    if (e.preventDefault) e.preventDefault();
    if (userCode !== e.target.value) setUserCode(e.target.value);
  }

  function testInputs(groupSelector) {
    const selector = `${groupSelector} input,${groupSelector} select,${groupSelector} textarea`;
    const inputs = document.querySelectorAll(selector);

    let validation = true;
    inputs.forEach(input => {
      const valid = input.checkValidity();
      if (!valid) {
        validation = false;
        input.reportValidity();
      }
    });

    return validation;
  }

  useEffect(() => {
    getQuote(userClubId, userCode || code || false);
  }, [userClubId, userCode, code]); // Empty array ensures that effect is only run on mount and unmount

  scrollToForm();

  return (
    <CatalogueProvider catalogue={catalogue} locale={locale}>
      <CatalogueContext.Consumer>
        {catalogue => (
          <form onSubmit={e => handleSubmitSignup(e)} className="evo-form">
            {//* *******************
            // First Step: Select studio and enter info
            //* *******************
            (step === "FORM-1" || step === "FORM-2" || step === "FORM-3") && (
              <>
                <div
                  style={{ display: step === "FORM-1" ? "block" : "none" }}
                  className="form-1"
                >
                  <div className="evo-form__cols">
                    {/* // !validatingPaymentInProgress && !paymentIframeOpen && !successMessage && */}
                    <div className="evo-form__col">
                      <h2 className="evo-form__headline">
                        {catalogue.headlineSelectClub}
                      </h2>
                      <SelectStudio
                        userClubId={userClubId}
                        clubName={clubName}
                        city={city}
                        onChange={e => handleSelectStudio(e)}
                        handleSelectStudio={e => {
                          handleSelectStudio(e);
                        }}
                        showStudioText
                      />
                      <div
                        className="evo-form__row evo-form__row--2"
                        style={{ opacity: clubSelected ? 1 : 0.5 }}
                      >
                        <InputCode
                          onChange={e => handleChangeCode(e)}
                          value={userCode}
                          disabled={!clubSelected}
                        />
                      </div>
                    </div>
                    {/* // !validatingPaymentInProgress && !paymentIframeOpen && !successMessage && */}
                    <div
                      className="evo-form__col"
                      style={{
                        opacity: clubSelected ? 1 : 0.5,
                        pointerEvents: clubSelected ? "all" : "none"
                      }}
                    >
                      {/* <h2 className="evo-form__headline">{catalogue.headlineCode}</h2> */}
                      {clubSelected && (
                        <Quote quoteData={quoteData} locale={locale}>
                          <button
                            type="button"
                            className="evo-form__button"
                            onClick={handleStartSignup}
                          >
                            {catalogue.startSignup}
                          </button>
                        </Quote>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: step === "FORM-2" ? "block" : "none" }}
                  className="form-2"
                >
                  <div className="evo-form__block">
                    <h2 className="evo-form__headline">
                      {catalogue.headlineContact}
                    </h2>
                    <div className="evo-form__row evo-form__row--2">
                      <InputName />
                    </div>
                    <div className="evo-form__row evo-form__row--2">
                      <InputAddress />
                    </div>
                    <div className="evo-form__row evo-form__row--2">
                      <SelectCountry />
                      <InputPhone />
                    </div>
                    <div className="evo-form__row evo-form__row--2">
                      <SelectGender />
                      <InputBirthdate />
                    </div>
                  </div>

                  <div className="evo-form__block">
                    <h2 className="evo-form__headline">
                      {catalogue.headlineCredentials}
                    </h2>
                    <p className="evo-form__text">
                      {catalogue.credentialsInfo}
                    </p>
                    <div className="evo-form__row">
                      <InputEmail />
                    </div>
                    <div className="evo-form__row">
                      <InputPassword />
                    </div>
                  </div>

                  <button
                    type="button"
                    disabled={!clubSelected}
                    className="evo-form__button"
                    onClick={handleContinueSignup}
                  >
                    {catalogue.startSignup}
                  </button>
                </div>
                <div
                  style={{ display: step === "FORM-3" ? "block" : "none" }}
                  className="form-3"
                >
                  <div className="evo-form__cols">
                    <div className="evo-form__col">
                      <div className="evo-form__block">
                        <h2 className="evo-form__headline">
                          {catalogue.headlinePrivacy}
                        </h2>
                        <div className="evo-form__row">
                          <OptionNewsletter />
                        </div>
                      </div>
                    </div>

                    <div className="evo-form__col">
                      <div className="evo-form__block">
                        <h2 className="evo-form__headline">
                          {catalogue.headlinePayment}
                        </h2>
                        <div className="evo-form__row">
                          <OptionPayment />
                        </div>
                        <div className="evo-form__row">
                          <TextAgree />
                        </div>
                      </div>
                      {sendFormInProgress && <Loader />}
                      <ButtonSubmit
                        sendFormInProgress={sendFormInProgress}
                        buttonText={catalogue.sendSignup}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {//* *******************
            // Step: Enter Payment details in Iframe
            //* *******************
            step === "PAYMENT_CREDIT_CARD" && (
              // paymentIframeOpen &&
              <iframe
                title="payment-credit-card"
                id="paymentIframe"
                style={{ border: "none" }}
                src={paymentIframeUrl}
                width="100%"
                height={900}
              />
            )}
            {//* *******************
            // Step: Enter Payment details in Iframe
            //* *******************
            step === "PAYMENT_SEPA" && (
              // paymentIframeOpen &&
              <div
                id="paymentIframe"
                dangerouslySetInnerHTML={{ __html: paymentIframeUrl }}
              />
            )}
            {//* *******************
            // Last Step: Check if payment is valid
            //* *******************
            step === "PAYMENT_VALIDATION" && (
              // validatingPaymentInProgress &&
              <div className="evo-form__text-content">
                <h1>Payment Validation</h1>
                <Loader />
              </div>
            )}
            {//* *******************
            // Sucess message
            //* *******************
            step === "SUCCESS" && (
              // successMessage &&
              <div className="evo-form__text-content">
                <h1>{catalogue.successSignupHeadline}</h1>
                <p>{catalogue.successSignupText}</p>
              </div>
            )}
            {message && (
              <MessageBox
                message={message}
                handleOkayClick={e => handleOkayClick(e)}
              />
            )}
          </form>
        )}
      </CatalogueContext.Consumer>
    </CatalogueProvider>
  );
}
